import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Site } from "@mindprint-learning/api-lib";

import { SiteFormData } from "../SitesPage/SiteForm/SiteForm.types";
import { SitesState } from "./types";

import api from "services/api";

const initialState: SitesState = {
  list: undefined,
  loading: false,
  hasError: false,
  showSiteForm: false,
  selectedSite: null,
  deletedSite: null,
};

export const fetchAllSites = createAsyncThunk("sites/fetch", async () => {
  return (await api.sites.getAllSites()).data;
});

export const fetchOrgSites = createAsyncThunk(
  "organizations/sites",
  async (orgId: number) => {
    return (await api.sites.getOrgSites(orgId)).data;
  }
);

export const createSite = createAsyncThunk(
  "sites/create",
  async (data: SiteFormData) => {
    return (await api.sites.createSite(data)).data;
  }
);

export const updateSite = createAsyncThunk(
  "sites/update",
  async (data: SiteFormData) => {
    return (await api.sites.updateSite(data)).data;
  }
);

export const deleteSite = createAsyncThunk(
  "sites/delete",
  async (data: Site) => {
    return (await api.sites.deleteSite(data)).data;
  }
);

export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    setSiteList: (state, action) => {
      state.list = action.payload;
    },
    setSiteIncludes: (state, action) => {
      const siteIncludes = action.payload;
      const updatedSiteIndex = state.list?.findIndex(
        (site) => site.id === siteIncludes.id
      );
      // @ts-ignore
      state.list[updatedSiteIndex] = siteIncludes;
    },
    setShowSiteForm: (state, action) => {
      state.showSiteForm = action.payload;
    },
    setSelectedSite: (state, action) => {
      state.selectedSite = action.payload;
    },
    setDeletedSite: (state, action) => {
      state.deletedSite = action.payload;
    },
    clearSitesState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSites.pending, (state) => {
        state.loading = true;
        state.list = undefined;
      })
      .addCase(fetchAllSites.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
      })
      .addCase(fetchAllSites.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchOrgSites.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOrgSites.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
      })
      .addCase(fetchOrgSites.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createSite.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSite.fulfilled, (state, action) => {
        state.loading = false;
        state.list?.push(action.payload);
        state.selectedSite = null;
      })
      .addCase(createSite.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(updateSite.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSite.fulfilled, (state, action) => {
        state.loading = false;
        const updatedSite = action.payload;
        const updatedSiteIndex = state.list?.findIndex(
          (site) => site.id === updatedSite.id
        );
        // @ts-ignore
        state.list[updatedSiteIndex] = updatedSite;
        state.selectedSite = null;
      })
      .addCase(updateSite.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(deleteSite.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSite.fulfilled, (state, action) => {
        state.loading = false;
        const deletedSite = action.payload;
        state.list = state.list?.filter((site) => site.id !== deletedSite.id);
        state.deletedSite = null;
      })
      .addCase(deleteSite.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export const setSiteList = sitesSlice.actions.setSiteList;
export const setSiteIncludes = sitesSlice.actions.setSiteIncludes;
export const setShowSiteForm = sitesSlice.actions.setShowSiteForm;
export const setSelectedSite = sitesSlice.actions.setSelectedSite;
export const setDeletedSite = sitesSlice.actions.setDeletedSite;
export const clearSitesState = sitesSlice.actions.clearSitesState;

export default sitesSlice.reducer;
