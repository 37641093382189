import { useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import loadable from "@loadable/component";
import { users_role } from "@mindprint-learning/api-lib";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { signout } from "modules/Auth/store";

// Components
import PageLayout from "layout/PageLayout";
import ReportPreview from "modules/Rules/RulesPage/ReportPreview";
import UserDetailPage from "modules/UserDetail/UserDetailPage";
import NWEAReport from "components/NWEAReport/NWEAReport";
import StudentLearnerProfile from "components/StudentLearnerProfile/StudentLearnerProfile";
import Sites from "./Sites";
import Manage from "./Manage";
import Reports from "./Reports";
import Questionnaire from "./Questionnaire";
import Rules from "./Rules";
import StrategiesRoutes from "./Strategies";
import TeacherTrials from "./TeacherTrials";
import SATACTReport from "components/SATACTReport/SATACTReport";
import SATVSACTReport from "components/SATVSACTReport/SATVSACTReport";
// import MindPrintProfile from "../components/MindPrintProfile/MindPrintProfile";
// import MindPrintProfilePrint from "../components/MindPrintProfile/MindPrintProfilePrint";

const LoginPage = loadable(() => import("modules/Auth/LoginPage"));
const PasswordResetRequestPage = loadable(
  () => import("modules/Auth/PasswordReset/RequestForm")
);
const PasswordResetEmailSentPage = loadable(
  () => import("modules/Auth/PasswordReset/EmailSent")
);
const PasswordResetPage = loadable(
  () => import("modules/Auth/PasswordReset/ResetForm")
);
const PasswordResetSuccessfulPage = loadable(
  () => import("modules/Auth/PasswordReset/Success")
);
// const SettingsPage = loadable(() => import("modules/Auth/SettingsPage"));

export enum RouteLink {
  Home = "/",
  People = "/people",
  Login = "/login",
  // Reports = "/reports",
  Rules = "/rules",
  Strategies = "/strategies",
  Settings = "/settings",
  MPProfile = "/mindprint-profile",
  SLProfile = "/student-learner-profile",
  NWEAMath = "/nwea-math-report",
  NWEAReading = "/nwea-reading-report",
  Questionnaire = "/questionnaire",
  TeacherTrials = "teacher-trials",
  SATVSACT = "/satvsact-combo-report",
  SAT = "/sat-report",
  ACT = "/act-report",
  PasswordResetRequest = "/forgot-password",
  PasswordResetEmailSent = "/reset-password-email-sent",
  PasswordReset = "/reset-password",
  PasswordResetSuccessful = "/reset-password-successful",
}

const PrivateRoute = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const location = useLocation();

  return user ? (
    <Outlet />
  ) : (
    <Navigate
      replace
      to={RouteLink.Login}
      state={{ path: location.pathname }}
    />
  );
};

const AppRoutes = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const token = useAppSelector((state: RootState) => state.auth.token);
  const routeRoots = useAppSelector((state) => state.auth.routeRoots);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user?.role === users_role.student) {
      dispatch(signout());
      window.location.replace(
        `${process.env.REACT_APP_STUDENT_URL}login?t=${token}`
      );
    }
  }, [user, dispatch, token]);

  return (
    <>
      {user?.role === users_role.student ? (
        <Routes>
          <Route
            path=""
            element={
              <PageLayout>
                <PrivateRoute />
              </PageLayout>
            }
          />

          <Route path={`${RouteLink.Login}/*`} element={<LoginPage />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path=""
            element={
              <PageLayout>
                <PrivateRoute />
              </PageLayout>
            }
          >
            <Route
              path={`${RouteLink.Home}`}
              element={<Navigate to={`${routeRoots?.manage || ""}`} />}
            />
            <Route
              path={`${routeRoots?.manage || ""}/*`}
              element={<Manage />}
            />
            {/* <Route path={`${RouteLink.Reports}/*`} element={<Reports />} /> */}

            <Route path={`${RouteLink.Rules}/*`} element={<Rules />} />
            <Route
              path={`${RouteLink.TeacherTrials}/*`}
              element={<TeacherTrials />}
            />

            <Route
              path={`${RouteLink.MPProfile}/:subid`}
              element={<ReportPreview />}
            />

            <Route
              path={`${RouteLink.MPProfile}/:subid/print`}
              element={<ReportPreview />}
            />

            <Route
              path={`${RouteLink.SLProfile}/:subid`}
              element={<StudentLearnerProfile />}
            />

            <Route
              path={`${RouteLink.NWEAMath}/:subid`}
              element={<NWEAReport />}
            />

            <Route
              path={`${RouteLink.NWEAReading}/:subid`}
              element={<NWEAReport />}
            />
            <Route
              path={`${RouteLink.SATVSACT}/:id`}
              element={<SATVSACTReport />}
            />
            <Route path={`${RouteLink.SAT}/:id`} element={<SATACTReport />} />
            <Route path={`${RouteLink.ACT}/:id`} element={<SATACTReport />} />
            <Route
              path={`${RouteLink.Strategies}/*`}
              element={<StrategiesRoutes />}
            />
            {/* <Route
              path={`${RouteLink.Settings}/*`}
              element={<SettingsPage />}
            />
            <Route
              path={`${RouteLink.People}/:userId/*`}
              element={<UserDetailPage />}
            /> */}
            <Route
              path={`${RouteLink.People}/:userId/*`}
              element={<UserDetailPage />}
            />
            <Route
              path={`${RouteLink.Questionnaire}/*`}
              element={<Questionnaire />}
            />
          </Route>

          <Route path={`${RouteLink.Login}/*`} element={<LoginPage />} />
          <Route
            path={`${RouteLink.PasswordResetRequest}/*`}
            element={<PasswordResetRequestPage />}
          />
          <Route
            path={`${RouteLink.PasswordResetEmailSent}/*`}
            element={<PasswordResetEmailSentPage />}
          />
          <Route
            path={`${RouteLink.PasswordReset}/*`}
            element={<PasswordResetPage />}
          />
          <Route
            path={`${RouteLink.PasswordResetSuccessful}/*`}
            element={<PasswordResetSuccessfulPage />}
          />
          <Route
            path={`/*`}
            element={<Navigate to={`${routeRoots?.manage || ""}`} />}
          />
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
