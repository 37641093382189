import { AxiosInstance } from "axios";
import { StudentReportProps } from "@mindprint-learning/design";

const studentLearnerProfileApi = (api: AxiosInstance) => {
  return {
    getStudentLearnerProfile: (subid: string | undefined) => {
      const path = subid ? `reports/learner-profile/2/${subid}` : "";
      return api.get<StudentReportProps>(`${path}`);
    },
  };
};

export default studentLearnerProfileApi;
