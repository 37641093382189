import { AxiosInstance } from "axios";
import { CognitiveTableData } from "modules/CognitiveTable/types";

const cognitiveTableApi = (api: AxiosInstance) => {
  return {
    fetchCognitiveTableData: (siteId: number) => {
      const path = siteId ? `/student-dashboard/sites/${siteId}` : "";
      return api.get<CognitiveTableData[]>(`/dashboard${path}`);
    },
  };
};

export default cognitiveTableApi;
