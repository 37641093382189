import { lifts as Lift } from "@mindprint-learning/api-lib";
import { AxiosInstance } from "axios";

const lifts = (api: AxiosInstance) => {
  return {
    fetch: () => api.get<Array<Lift>>("lifts"),
    get: (id: string | number) => api.get<Lift>(`lifts/${id}`),
    create: (lift: Lift) => {
      let formData = new FormData();
      for (const field of Object.entries(lift)) {
        const value = field[1];
        if (value) {
          formData.append(field[0], value.toString());
        }
      }

      return api.post<Lift>("lifts", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    update: (id: number, lift: Lift) => {
      let formData = new FormData();
      for (const field of Object.entries(lift)) {
        const value = field[1];

        if (value) {
          formData.append(field[0], value.toString());
          console.log(`${field[0]}: ${value}`);
        }
      }

      return api.put<Lift>(`lifts/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    delete: (id: number) => api.delete<Lift>(`lifts/${id}`),
  };
};

export default lifts;
