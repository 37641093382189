import { useState } from "react";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { Button, ButtonSizes } from "@mindprint-learning/design";
import { Rule } from "services/api/rules";

import styles from "../../rules.module.css";

interface RuleBoxProps {
  rule: Rule;
}
const RuleBox = ({ rule }: RuleBoxProps) => {
  const [showRule, setShowRule] = useState(false);
  return (
    <div
      className={styles.ruleBox}
      style={{
        borderWidth: 2,
        borderColor: "#ecf0f1",
        // borderColor: rule.v1Match ? "#07b793" : "#ecf0f1",
        borderStyle: "solid",
      }}
    >
      {rule.tier && <div className={styles.ruleHeader}>{rule.tier}</div>}
      <div className={styles.ruleContainer}>
        <div className={styles.ruleCategory}>
          {rule.category} (id: {rule.id})
        </div>
        <div style={{ fontSize: "0.75em" }}>
          <a
            href={`https://admin.mindprintlearning.com/rules-engine?datasetid=${rule.datasetid}`}
            target="_blank"
            rel="noreferrer"
          >
            {rule.datasetid} <FaArrowUpRightFromSquare />
          </a>
        </div>
        <Button
          size={ButtonSizes.tiny}
          label={`${showRule ? "hide" : "show"} rule`}
          onClick={() => setShowRule(!showRule)}
        />
        {showRule && (
          <div>
            {/* <RuleLogicView value={rule.expression} /> */}
            <pre style={{ fontFamily: "monospace", fontSize: "0.875em" }}>
              {JSON.stringify(
                JSON.parse(rule.expression?.toString() || ""),
                null,
                2
              )}
            </pre>
          </div>
        )}
      </div>
      {rule.minor_category && (
        <div className={styles.ruleHeader}>{rule.minor_category}</div>
      )}
      {rule.parent_tier && (
        <div className={styles.ruleHeader}>{rule.parent_tier}</div>
      )}
    </div>
  );
};

export default RuleBox;
