import loadable from "@loadable/component";
import { Outlet, Route, Routes } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";

const QuestionnairePage = loadable(
  () => import("modules/Questionnaire/Questionnaire")
);

const Questionnaire = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);

  return (
    <>
      {user && (
        <>
          <Routes>
            <Route
              path="*"
              element={
                <>
                  <Outlet />
                </>
              }
            >
              <Route path="*" element={<QuestionnairePage />}></Route>
            </Route>
          </Routes>
        </>
      )}
    </>
  );
};

export default Questionnaire;
