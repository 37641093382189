import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TeacherTrialState } from "./types";
import api from "services/api";

const initialState: TeacherTrialState = {
  data: undefined,
  loading: false,
  hasError: false,
};

export const fetchTeacherTrialData = createAsyncThunk(
  "fetchTeacherTrialData",
  async () => {
    return (await api.teacherTrial.fetchTeacherTrialData()).data.data;
  }
);

const TeacherTrialSlice = createSlice({
  name: "TeacherTrial",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeacherTrialData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTeacherTrialData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchTeacherTrialData.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export default TeacherTrialSlice.reducer;
