import { AxiosInstance } from "axios";
import { ProfileObj } from "../../components/MindPrintProfile/store/types";

const mindPrintProfileApi = (api: AxiosInstance) => {
  return {
    getMindPrintProfile: (subid: string | undefined) => {
      const path = subid ? `mindprint-profile/${subid}` : "";
      return api.get<ProfileObj>(`reports/${path}`);
    },
  };
};

export default mindPrintProfileApi;
