import { AxiosInstance } from "axios";
import { Questionnaire, Response } from "modules/Questionnaire/store/types";

const questionnaireApi = (api: AxiosInstance) => {
  return {
    getQuestionnaire: () => {
      return api.get<Questionnaire>(`questionnaire/4`);
    },
    submit: (data: Response) => {
      return api.post<Response>(`questionnaire/payload`, data);
    },
  };
};

export default questionnaireApi;
