import { FaCheck, FaBan } from "react-icons/fa6";
import {
  Button,
  ButtonTypes,
  Modal,
  ModalTypes,
} from "@mindprint-learning/design";

import styles from "./modals.module.css";

const Reprocess = ({ show, close, process }: ReprocessProps) => {
  return (
    <Modal
      show={show}
      type={ModalTypes.warning}
      handleClose={() => close()}
      title="Are you sure?"
    >
      <div className={`${styles.main} ${styles.maxWidth400}`}>
        <div>
          This action is destructive. Re-processing will update all of the 2.0
          information for this student and undoing it may not be possible.
        </div>
        <div className={styles.buttonContainer}>
          <Button
            label={
              <span className={styles.buttonText}>
                <FaCheck />
                <p>Yes, re-process this profile</p>
              </span>
            }
            type={ButtonTypes.ok}
            onClick={() => {
              process();
              close();
            }}
          />
          <Button
            label={
              <span className={styles.buttonText}>
                <FaBan />
                <p>Cancel</p>
              </span>
            }
            type={ButtonTypes.cancel}
            onClick={() => {
              close();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Reprocess;

interface ReprocessProps {
  show: boolean;
  close: () => void;
  process: () => void;
}
