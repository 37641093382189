import { AxiosInstance } from "axios";

import { boosts as Boost } from "@mindprint-learning/api-lib";

const boosts = (api: AxiosInstance) => {
  return {
    fetch: () => api.get<Array<Boost>>("boosts"),
    get: (id: string | number) => api.get<Boost>(`boosts/${id}`),
    create: (boost: Boost) => {
      let formData = new FormData();
      for (const field of Object.entries(boost)) {
        const value = field[1];
        if (value) {
          formData.append(field[0], value.toString());
        }
      }

      return api.post<Boost>("boosts", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    update: (id: number, boost: Boost) => {
      console.log("boosts.ts:update), boost=", boost);
      let formData = new FormData();
      for (const field of Object.entries(boost)) {
        const value = field[1];

        if (value) {
          formData.append(field[0], value.toString());
          console.log(`${field[0]}: ${value}`);
        }
      }

      return api.put<Boost>(`boosts/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    delete: (id: number) => api.delete<Boost>(`boosts/${id}`),
  };
};

export default boosts;
