import { useEffect, useState } from "react";
import { FaPencil } from "react-icons/fa6";
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  DOBInput,
  Input,
  Label,
  ProfileImageModal,
} from "@mindprint-learning/design";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { uploadProfileImage } from "../store";

import { PageSection, PageSubsection } from "components/PageSection";
import UserProfileCircle from "components/UserProfileCircle";
import { validEmail } from "utils/formValidator";

import styles from "./Info.module.css";

const Info = ({ sectionRef, onInView }: InfoProps) => {
  const user = useAppSelector((state: RootState) => state.userDetail.user);
  const destructive = useAppSelector(
    (state: RootState) => state.userDetail.destructive
  );

  const dispatch = useAppDispatch();

  const [firstName, setFirstName] = useState(user?.first_name || "");
  const [lastName, setLastName] = useState(user?.last_name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [dob, setDOB] = useState<Date | undefined>(
    user?.student?.dob ? new Date(user?.student?.dob) : undefined
  );

  const firstNameValid = firstName.length > 0 && firstName !== user?.first_name;
  const lastNameValid = lastName.length > 0 && lastName !== user?.last_name;
  const emailValid = validEmail(email) && email !== user?.email;

  const [showProfileImageModal, setShowProfileImageModal] = useState(false);

  useEffect(() => {
    setFirstName(user?.first_name || "");
    setLastName(user?.last_name || "");
    setEmail(user?.email || "");

    if (user?.student?.dob) {
      const d = new Date(user?.student?.dob);
      setDOB(d);
    }
  }, [user]);

  const saveProfileImage = (
    base64Image: string | null,
    backgroundColor: string | null,
    defaultImage: string | null
  ): Promise<any> => {
    const userId = user?.id;
    if (userId) {
      return dispatch(
        uploadProfileImage({
          userId,
          base64Image,
          backgroundColor,
          defaultImage,
        })
      );
    } else {
      return new Promise((resolve) => resolve("none"));
    }
  };
  return (
    <PageSection
      title="Information"
      sectionRef={sectionRef}
      onInView={onInView}
    >
      <ProfileImageModal
        handleImageSave={saveProfileImage}
        show={showProfileImageModal}
        close={() => setShowProfileImageModal(false)}
      />
      <PageSubsection
        title="Profile Picture"
        description="Update the profile picture for this student"
      >
        <div className={styles.profileImageWrapper} style={{ width: 170 }}>
          {user && <UserProfileCircle user={user} size={170} />}
          <Button
            onClick={() => setShowProfileImageModal(true)}
            size={ButtonSizes.tiny}
            label={
              <span className={styles.buttonText}>
                <FaPencil />
                <p>Edit Profile Picture</p>
              </span>
            }
          />
        </div>
      </PageSubsection>
      <PageSubsection
        title="Details"
        description="Modify student information details"
      >
        <div className={styles.inputWrapper}>
          <div className={styles.inputRow}>
            <div className={styles.inputContainer}>
              {firstName && (
                <Input
                  label="First Name"
                  id="info_input_firstName"
                  value={firstName}
                  onChange={setFirstName}
                  valid={firstNameValid}
                  disabled={!destructive}
                />
              )}
            </div>
            <div className={styles.inputContainer}>
              {lastName && (
                <Input
                  label="Last Name"
                  id="info_input_lastName"
                  value={lastName}
                  onChange={setLastName}
                  valid={lastNameValid}
                  disabled={!destructive}
                />
              )}
            </div>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputContainer}>
              {email && (
                <Input
                  label="Email"
                  id="info_input_email"
                  value={email}
                  onChange={setEmail}
                  valid={emailValid}
                  // disabled={!destructive}
                />
              )}
            </div>
          </div>
          {user?.student && (
            <div className={styles.inputRow}>
              <div className={styles.inputContainer}>
                <Label htmlFor="info_select_dateOfBirth">Date of Birth</Label>
                <DOBInput
                  id="info_select_dateOfBirth"
                  value={dob}
                  onChange={setDOB}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            label="Save"
            type={ButtonTypes.cta}
            primary={true}
            onClick={() => alert("BOOM!")}
            disabled={!firstNameValid && !lastNameValid}
          />
        </div>
      </PageSubsection>
    </PageSection>
  );
};

export default Info;

interface InfoProps {
  sectionRef?: any;
  onInView?: () => void;
}
