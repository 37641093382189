import { UsersResponse, Student } from "@mindprint-learning/api-lib";
import { AxiosInstance } from "axios";
import { NewUser } from "modules/Users/components/UserForm/UserForm.types";
import { User, Student as FEStudent } from "modules/Users/Types/User.types";
import { UserOrgResponse, UserSiteResponse } from "modules/Auth/store/types";

const users = (api: AxiosInstance) => {
  return {
    // Users
    getSitesUsers: (siteId: number) => {
      const includeParams = ["reports", "assessment-progress"];
      const params = {
        include: includeParams.join(","),
        pageSize: 4000,
      };
      return api.get<UsersResponse>(`sites/${siteId}/users`, { params });
    },

    getAdminUsers: (orgId: number) => {
      return api.get<UsersResponse>(
        `organizations/${orgId}/users?role=org_admin`
      );
    },

    // User
    getOne: (id: number) => {
      return api.get<UsersResponse>(`users/${id}`);
    },
    getAssessments: (id: number) => {
      return api.get<Array<any>>(`users/${id}/assessments`);
    },
    getUserOrg: (id: number) => {
      return api.get<UserOrgResponse>(`users/${id}/organizations`);
    },
    getUserSites: (id: number) => {
      const params = {
        include: "counts",
        pageSize: 4000,
      };
      return api.get<UserSiteResponse>(`users/${id}/sites`, { params });
    },
    create: (data: NewUser) => {
      return api.post<User>(`users`, data);
    },
    update: (data: User) => {
      return api.put<User>(`users/${data.id}`, data);
    },
    delete: (data: User) => {
      return api.delete<User>(`users/${data.id}`);
    },

    // Student
    getStudent: (userId: number) => {
      const params = {
        include: "student",
      };
      return api.get<Student>(`users/${userId}`, { params });
    },
    updateStudent: (data: FEStudent) => {
      return api.put<FEStudent>(`students/${data.id}`, data);
    },
    deleteStudent: (data: User) => {
      return api.delete<FEStudent>(`students/${data.student!.id}`);
    },
  };
};

export default users;
