import { ScoreRangeValues, Skill, Skills } from "@mindprint-learning/api-lib";

import styles from "./ScoreRangeTable.module.css";

interface ScoreRangeTableProps {
  data: Skills;
}

// Sort them by the overall zScore high to low
const ScoreRangeTable = ({ data }: ScoreRangeTableProps) => {
  return (
    <>
      {data ? (
        <>
          <div style={{ display: "flex" }}>
            <div className={styles.column}>
              <div className={styles.headerCell}>Invalid</div>
              <div>
                {Object.values(data)
                  .filter((value: Skill) => !value.valid)
                  .sort((a, b) =>
                    a.overall.zScore < b.overall.zScore ? 1 : -1
                  )
                  .map((value: Skill, index) => {
                    return (
                      <div className={styles.cell} key={`score-range-${index}`}>
                        {value.label}
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className={styles.column}>
              <div className={styles.headerCell}>Low Weaknesses</div>
              <div>
                {Object.values(data)
                  .filter(
                    (value: Skill) =>
                      value.overall.range === ScoreRangeValues.low_weakness &&
                      value.valid
                  )
                  .sort((a, b) =>
                    a.overall.zScore < b.overall.zScore ? 1 : -1
                  )
                  .map((value: Skill, index) => {
                    return (
                      <div className={styles.cell} key={`score-range-${index}`}>
                        {value.label}
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className={styles.column}>
              <div className={styles.headerCell}>Medium Weaknesses</div>
              <div>
                {Object.values(data)
                  .filter(
                    (value: Skill) =>
                      value.overall.range ===
                        ScoreRangeValues.medium_weakness && value.valid
                  )
                  .sort((a, b) =>
                    a.overall.zScore < b.overall.zScore ? 1 : -1
                  )
                  .map((value: Skill, index) => {
                    return (
                      <div className={styles.cell} key={`score-range-${index}`}>
                        {value.label}
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className={styles.column}>
              <div className={styles.headerCell}>Expected Low</div>
              <div>
                {Object.values(data)
                  .filter(
                    (value: Skill) =>
                      value.overall.range === ScoreRangeValues.low_expected &&
                      value.valid
                  )
                  .sort((a, b) =>
                    a.overall.zScore < b.overall.zScore ? 1 : -1
                  )
                  .map((value: Skill, index) => {
                    return (
                      <div className={styles.cell} key={`score-range-${index}`}>
                        {value.label}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.headerCell}>Expected Medium</div>
              <div>
                {Object.values(data)
                  .filter(
                    (value: Skill) =>
                      value.overall.range ===
                        ScoreRangeValues.medium_expected && value.valid
                  )
                  .sort((a, b) =>
                    a.overall.zScore < b.overall.zScore ? 1 : -1
                  )
                  .map((value: Skill, index) => {
                    return (
                      <div className={styles.cell} key={`score-range-${index}`}>
                        {value.label}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.headerCell}>Expected High</div>
              <div>
                {Object.values(data)
                  .filter(
                    (value: Skill) =>
                      value.overall.range === ScoreRangeValues.high_expected &&
                      value.valid
                  )
                  .sort((a, b) =>
                    a.overall.zScore < b.overall.zScore ? 1 : -1
                  )
                  .map((value: Skill, index) => {
                    return (
                      <div className={styles.cell} key={`score-range-${index}`}>
                        {value.label}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.headerCell}>Medium Strength</div>
              <div>
                {Object.values(data)
                  .filter(
                    (value: Skill) =>
                      value.overall.range ===
                        ScoreRangeValues.medium_strength && value.valid
                  )
                  .sort((a, b) =>
                    a.overall.zScore < b.overall.zScore ? 1 : -1
                  )
                  .map((value: Skill, index) => {
                    return (
                      <div className={styles.cell} key={`score-range-${index}`}>
                        {value.label}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.headerCell}>High Strength</div>
              <div>
                {Object.values(data)
                  .filter(
                    (value: Skill) =>
                      value.overall.range === ScoreRangeValues.high_strength &&
                      value.valid
                  )
                  .sort((a, b) =>
                    a.overall.zScore < b.overall.zScore ? 1 : -1
                  )
                  .map((value: Skill, index) => {
                    return (
                      <div className={styles.cell} key={`score-range-${index}`}>
                        {value.label}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>loading</>
      )}
    </>
  );
};

export default ScoreRangeTable;
