import { reports_flags } from "@mindprint-learning/api-lib";
import { CellTypes, Table, TableThemes } from "@mindprint-learning/design";
import { useMemo } from "react";

const Flags = ({ value }: FlagsProps) => {
  const headers = useMemo(() => {
    return [
      {
        label: "Type",
        accessor: "flag_type",
        type: CellTypes.string,
      },
      {
        label: "Description",
        accessor: "description",
        type: CellTypes.string,
      },
      {
        label: "Last Updated",
        accessor: "updated_at",
        type: CellTypes.date,
      },
    ];
  }, []);
  return (
    <div>
      <Table
        pageSize={10}
        rows={value}
        headers={headers}
        theme={TableThemes.minimal}
        // edit={() => alert("EDIT!")}
        // del={() => alert("DELETE!")}
      />
    </div>
  );
};

export default Flags;

interface FlagsProps {
  value: Array<reports_flags>;
}
