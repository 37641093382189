import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mindprint-learning/design";

import Skeleton from "react-loading-skeleton";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCallback, useEffect, useState } from "react";
import { fetch, clear, fetchAssessments } from "../store";
import { RootState } from "store/store";

import styles from "./UserDetailPage.module.css";

import { SideNav, SideNavLink } from "components/SideNav";
import Info from "./Info";
import Assessments from "./Assessments";
import Reports from "./Reports";

const UserDetailPage = () => {
  const user = useAppSelector((state: RootState) => state.userDetail.user);

  const params = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const userId = parseInt(params.userId || "");
    dispatch(fetch(userId));
    dispatch(fetchAssessments(userId));
  }, [dispatch, params.userId]);

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, [dispatch]);

  // Scrollable Page
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const updateSelectedIndex = (value: number) => {
    setSelectedIndex(value);
  };

  // Student Info
  const [studentRef, setStudentRef] = useState<any>(null);
  const getStudentRef = useCallback((node) => {
    if (node) setStudentRef(node);
    return node;
  }, []);

  useEffect(() => {
    const subPage =
      location.pathname.split("/")[location.pathname.split("/").length - 1];

    if (subPage === "info" && studentRef) {
      studentRef?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, studentRef]);

  // Assessments
  const [assessRef, setAssessRef] = useState<any>(null);
  const getAssessRef = useCallback(
    (
      node: React.RefObject<HTMLDivElement>
    ): React.RefObject<HTMLDivElement> => {
      if (node) setAssessRef(node);
      return node;
    },
    []
  );
  useEffect(() => {
    const subPage =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (subPage === "assessments" && assessRef) {
      assessRef?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, assessRef]);

  // Reports
  const [reportsRef, setReportsRef] = useState<any>(null);
  const getReportsRef = useCallback(
    (
      node: React.RefObject<HTMLDivElement>
    ): React.RefObject<HTMLDivElement> => {
      if (node) setReportsRef(node);
      return node;
    },
    []
  );
  useEffect(() => {
    const subPage =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
    if (subPage === "reports" && reportsRef) {
      reportsRef?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, reportsRef]);

  const Nav = (
    <SideNav>
      <SideNavLink
        selected={selectedIndex === 0}
        onClick={() => navigate("info")}
      >
        Information
      </SideNavLink>
      <SideNavLink
        selected={selectedIndex === 1}
        onClick={() => navigate("assessments")}
      >
        Assessments
      </SideNavLink>
      <SideNavLink
        selected={selectedIndex === 2}
        onClick={() => navigate("reports")}
      >
        Reports
      </SideNavLink>
    </SideNav>
  );

  return (
    <>
      {Nav}

      <div className={styles.main}>
        <Container>
          <div className={styles.contentContainer}>
            <div>
              <h1>
                {user ? (
                  `${user.first_name} ${user.last_name}`
                ) : (
                  <div style={{ display: "flex", gap: "0.5em" }}>
                    <Skeleton style={{ width: 100 }} />
                    <Skeleton style={{ width: 300 }} />
                  </div>
                )}
              </h1>
            </div>
            {user && (
              <div className={styles.content}>
                <Info
                  sectionRef={getStudentRef}
                  onInView={() => updateSelectedIndex(0)}
                />
                <Assessments
                  user={user}
                  sectionRef={getAssessRef}
                  onInView={() => updateSelectedIndex(1)}
                />

                <Reports
                  sectionRef={getReportsRef}
                  onInView={() => updateSelectedIndex(2)}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
      {/* </div> */}
    </>
  );
};

export default UserDetailPage;
