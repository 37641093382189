import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AcademicTableState } from "./types";
import api from "services/api";

const initialState: AcademicTableState = {
  data: [],
  isNWEA: false,
  loading: false,
  hasError: false,
};

export const fetchAcademicTableData = createAsyncThunk(
  "fetchAcademicTableData",
  async (siteId: number) => {
    return (await api.academicTable.fetchAcademicTableData(siteId)).data;
  }
);

const AcademicTableSlice = createSlice({
  name: "AcademicTable",
  initialState,
  reducers: {
    clearAcademicTableState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAcademicTableData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAcademicTableData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.isNWEA = action.payload.meta.isNWEAUsed;
      })
      .addCase(fetchAcademicTableData.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export default AcademicTableSlice.reducer;

export const clearAcademicTableState =
  AcademicTableSlice.actions.clearAcademicTableState;
