import { useState, useEffect } from "react";
import { FaPlus, FaPencil, FaTrashCan } from "react-icons/fa6";
import { OrderedRulesTemplates } from "@mindprint-learning/api-lib";
import { Button, ButtonSizes, ButtonTypes } from "@mindprint-learning/design";

import CustomRule, {
  FormData,
  CustomRuleType,
  UpdatedRuleType,
} from "./modals/CustomRule";
import DeleteModal from "components/DeleteModal";
import { combineSortRules } from "utils/combineSortRules";
import styles from "../../rules.module.css";

// TODO: Minor Rules can populate these but should be smaller / italicised (needs design discussion)
const ClinicianSummary = ({
  data,
  customRules,
  createCustomRule,
  updateCustomRule,
  deleteCustomRule,
}: ClinicianSummaryProps) => {
  const [showCustomRule, setShowCustomRule] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editedRule, setEditedRule] = useState<CustomRuleType[]>([]);
  const [deletedRule, setDeletedRule] = useState<CustomRuleType[]>([]);
  const [deleteMessage, setDeleteMessage] = useState<string>(``);
  const [combinedRules, setCombinedRules] = useState<CombinedRuleObj>();

  // From Custom Rule Form
  const handleCreateCustomRule = (formData: FormData) => {
    createCustomRule(formData);
  };

  const handleUpdateCustomRule = (updatedRule: UpdatedRuleType) => {
    updateCustomRule(updatedRule);
  };

  const handleDeleteCustomRule = (id: number) => {
    deleteCustomRule(id);
  };

  const handleCancelCustomRule = () => {
    setEditedRule([]);
  };

  const handleCloseModal = () => {
    setEditedRule([]);
    setShowCustomRule(false);
  };

  // From list of Custom Rules
  const handleEdit = (id: number) => {
    const selectedRule = customRules.filter((rule) => rule.id === id);
    setEditedRule(selectedRule);
    setShowCustomRule(true);
  };

  const handleDelete = (id: number) => {
    const selectedRule = customRules.filter((rule) => rule.id === id);
    const message = `Delete the ${selectedRule[0].label} Custom Rule?`;
    setDeletedRule(selectedRule);
    setDeleteMessage(message);
    setShowDelete(true);
  };

  useEffect(() => {
    const ruleList = combineSortRules(data, customRules);
    setCombinedRules(ruleList);
  }, [data, customRules]);

  return (
    <>
      <CustomRule
        show={showCustomRule}
        close={handleCloseModal}
        create={handleCreateCustomRule}
        rule={editedRule}
        update={handleUpdateCustomRule}
        onCancel={handleCancelCustomRule}
      />

      <DeleteModal
        show={showDelete}
        onCancel={() => setShowDelete(false)}
        message={deleteMessage}
        onConfirm={() => handleDeleteCustomRule(deletedRule[0].id)}
      />

      <div className={styles.main}>
        <div>
          <Button
            label={
              <span className={styles.buttonText}>
                <FaPlus />
                <p>Add a Custom Rule</p>
              </span>
            }
            onClick={() => setShowCustomRule(true)}
            size={ButtonSizes.tiny}
            type={ButtonTypes.ok}
          />
        </div>

        {combinedRules ? (
          <>
            <h3>Strengths:</h3>
            <ul className={styles.list}>
              {combinedRules.strengths.map((value, index) => {
                return (
                  <div
                    key={`strengths-content-${index}`}
                    className={styles.ruleWrapper}
                  >
                    <li>
                      {value.label && <b>{value.label}:</b>} {value.content}{" "}
                      (order: {value.order}, rule: {value.rule_id})
                    </li>
                    {!value.rule_id && (
                      <section className={styles.buttonWrapper}>
                        <Button
                          label={
                            <>
                              <FaPencil />
                            </>
                          }
                          onClick={() => handleEdit(value.id)}
                          size={ButtonSizes.small}
                          type={ButtonTypes.ok}
                        />
                        <Button
                          label={
                            <>
                              <FaTrashCan />
                            </>
                          }
                          onClick={() => handleDelete(value.id)}
                          size={ButtonSizes.small}
                          type={ButtonTypes.ok}
                        />
                      </section>
                    )}
                  </div>
                );
              })}
            </ul>

            <h3>Needs:</h3>
            <ul className={styles.list}>
              {combinedRules.needs.map((value, index) => {
                return (
                  <div
                    key={`needs-content-${index}`}
                    className={styles.ruleWrapper}
                  >
                    <li>
                      <b>{value.label}:</b> {value.content} (order:{" "}
                      {value.order}, rule: {value.rule_id})
                    </li>
                    {!value.rule_id && (
                      <section className={styles.buttonWrapper}>
                        <Button
                          label={
                            <>
                              <FaPencil />
                            </>
                          }
                          onClick={() => handleEdit(value.id)}
                          size={ButtonSizes.small}
                          type={ButtonTypes.ok}
                        />
                        <Button
                          label={
                            <>
                              <FaTrashCan />
                            </>
                          }
                          onClick={() => handleDelete(value.id)}
                          size={ButtonSizes.small}
                          type={ButtonTypes.ok}
                        />
                      </section>
                    )}
                  </div>
                );
              })}
            </ul>

            <h3>Recommendations:</h3>
            <ul className={styles.list}>
              {combinedRules.recommendations.map((value, index) => {
                return (
                  <div
                    key={`recommendations-content-${index}`}
                    className={styles.ruleWrapper}
                  >
                    <li>
                      <b>{value.label}:</b> {value.content} (order:{" "}
                      {value.order}, rule: {value.rule_id})
                    </li>
                    {!value.rule_id && (
                      <section className={styles.buttonWrapper}>
                        <Button
                          label={
                            <>
                              <FaPencil />
                            </>
                          }
                          onClick={() => handleEdit(value.id)}
                          size={ButtonSizes.small}
                          type={ButtonTypes.ok}
                        />
                        <Button
                          label={
                            <>
                              <FaTrashCan />
                            </>
                          }
                          onClick={() => handleDelete(value.id)}
                          size={ButtonSizes.small}
                          type={ButtonTypes.ok}
                        />
                      </section>
                    )}
                  </div>
                );
              })}
            </ul>
          </>
        ) : (
          <>loading...</>
        )}
      </div>
    </>
  );
};

export default ClinicianSummary;

interface ClinicianSummaryProps {
  data: {
    strengths: OrderedRulesTemplates[];
    needs: OrderedRulesTemplates[];
    recommendations: OrderedRulesTemplates[];
  };
  customRules: CustomRuleType[];
  createCustomRule: (formData: FormData) => void;
  updateCustomRule: (updatedRule: UpdatedRuleType) => void;
  deleteCustomRule: (id: number) => void;
}

interface CombinedRule {
  assessmentId?: number;
  content: string;
  created_at: string;
  deleted_at?: string | null;
  id: number;
  index?: number;
  label: string;
  order: number;
  rule_id?: number;
  type: string;
  updated_at: string;
  version: number;
}

export interface CombinedRuleObj {
  strengths: CombinedRule[];
  needs: CombinedRule[];
  recommendations: CombinedRule[];
}
