import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import academicTablePageReducer from "modules/AcademicTable/store";
import assessmentsReducer from "modules/Assessments/store";
import authReducer from "modules/Auth/store";
import boostsReducer from "modules/Strategies/store/boost";
import cognitiveTablePageReducer from "modules/CognitiveTable/store";
import enrollmentReducer from "modules/Enrollment/store";
import liftsReducer from "modules/Strategies/store/lift";
import mindPrintProfileReducer from "components/MindPrintProfile/store";
import nweaReportReducer from "components/NWEAReport/store";
import satActReportReducer from "components/SATACTReport/store";
import organizationsReducer from "modules/Organizations/store";
import questionnaireReducer from "modules/Questionnaire/store";
import rulesReducer from "modules/Rules/store";
import sectionsReducer from "modules/Sections/store";
import sitesReducer from "modules/Sites/store";
import studentLearnerProfileReducer from "components/StudentLearnerProfile/store";
import teacherTrialReducer from "modules/TeacherTrial/store";
import userDetailReducer from "modules/UserDetail/store";
import usersReducer from "modules/Users/store";
import satVsActReportReducer from "components/SATVSACTReport/store";

export const store = configureStore({
  reducer: {
    academicTable: academicTablePageReducer,
    assessments: assessmentsReducer,
    auth: authReducer,
    boosts: boostsReducer,
    cognitiveTable: cognitiveTablePageReducer,
    enrollment: enrollmentReducer,
    lifts: liftsReducer,
    mindPrintProfile: mindPrintProfileReducer,
    nweaReport: nweaReportReducer,
    satActReport: satActReportReducer,
    satVsActReport: satVsActReportReducer,
    organizations: organizationsReducer,
    questionnaire: questionnaireReducer,
    rules: rulesReducer,
    sections: sectionsReducer,
    sites: sitesReducer,
    studentLearnerProfile: studentLearnerProfileReducer,
    teacherTrial: teacherTrialReducer,
    userDetail: userDetailReducer,
    users: usersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
