import { AxiosInstance } from "axios";
import { SATVSACTReportProps } from "@mindprint-learning/design";

const satVsActReportApi = (api: AxiosInstance) => {
  return {
    getSatVsActReport: (id: number | undefined) => {
      const path = id ? `/assessments/${id}/reports/test-prep` : null;
      return api.get<SATVSACTReportProps>(`${path}`, {
        params: {
          type: "combo",
        },
        headers: {
          //@ts-ignore
          "x-new-assessment-flow": true,
        },
      });
    },
  };
};

export default satVsActReportApi;
