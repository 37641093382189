import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SATVSACTState } from "./types";
import api from "services/api";
import { SATVSACTReportProps } from "@mindprint-learning/design/build/templates/SATVSACTReport/SATVSACTReport.types";

const initialState: SATVSACTState = {
  data: null,
  loading: false,
  hasError: false,
};

export const fetchSATVSACTReport = createAsyncThunk(
  "fetchSATVSACTReport",
  async (id: number | undefined) => {
    const response = await api.satVsActReport.getSatVsActReport(id);
    return response.data as SATVSACTReportProps;
  }
);

const SATVSACTSlice = createSlice({
  name: "satVsActReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSATVSACTReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSATVSACTReport.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSATVSACTReport.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export default SATVSACTSlice.reducer;
