import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import { profile_images } from "@mindprint-learning/api-lib";

import academicTable from "./academicTable";
import assessments from "./assessments";
import auth from "./auth";
import boosts from "./boosts";
import cognitiveTable from "./cognitiveTable";
import enrollment from "./enrollment";
import lifts from "./lifts";
import mindPrintProfile from "./mindPrintProfile";
import nweaReport from "./nweaReport";
import satActReport from "./satActReport";
import satVsActReport from "./satVsActReport";
import organizations from "./organizations";
import questionnaire from "./questionnaire";
import rules from "./rules";
import sections from "./sections";
import sites from "./sites";
import studentLearnerProfile from "./studentLearnerProfile";
import teacherTrial from "./teacherTrial";
import users from "./users";

const createAxiosInstance = (baseURL: string, _timeout: number) => {
  const instance: AxiosInstance = axios.create({
    baseURL,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response?.status;
      if (status === 404) {
        // Handle 404 error
        console.error("404 Not Found");
        return Promise.reject(error);
      }

      const msg = error.response?.data?.msg || "Failed to connect to server";
      console.error(msg);
      return Promise.reject(error);
    }
  );

  // see: https://stackoverflow.com/questions/47715451/localstorage-item-not-updating-in-axios-headers
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        } as AxiosRequestHeaders;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

const authServiceClient = createAxiosInstance(
  process.env.REACT_APP_AUTH_SVC_URL || "",
  30000
);

const api = createAxiosInstance(process.env.REACT_APP_API_URL || "", 30000);

const apiService = {
  academicTable: academicTable(api),
  assessments: assessments(api),
  auth: auth(authServiceClient, api),
  boosts: boosts(api),
  cognitiveTable: cognitiveTable(api),
  enrollment: enrollment(api),
  lifts: lifts(api),
  mindPrintProfile: mindPrintProfile(api),
  nweaReport: nweaReport(api),
  satActReport: satActReport(api),
  satVsActReport: satVsActReport(api),
  organizations: organizations(api),
  questionnaire: questionnaire(api),
  rules: rules(api),
  sections: sections(api),
  sites: sites(api),
  studentLearnerProfile: studentLearnerProfile(api),
  teacherTrial: teacherTrial(api),
  users: users(api),
  // media
  uploadProfileImage: ({
    userId,
    base64Image,
    backgroundColor,
    defaultImage,
  }: UpdateProfilePictureRequest) =>
    api.post<profile_images>(`admin/users/${userId}/profile-image`, {
      base64Image: base64Image,
      backgroundColor: backgroundColor,
      defaultImage: defaultImage,
    }),
};

export default apiService;

export interface UpdateProfilePictureRequest {
  userId: number;
  base64Image: string | null;
  backgroundColor: string | null;
  defaultImage: string | null;
}

// api nesting: https://stackoverflow.com/questions/20951419/what-are-best-practices-for-rest-nested-resources
