import { AxiosInstance } from "axios";
import { EnrollData, EnrollResponse } from "modules/Enrollment/store/types";

const enrollmentApi = (api: AxiosInstance) => {
  return {
    sectionEnroll: (data: EnrollData) => {
      return api.post<EnrollResponse>(`enrollments/sections`, data);
    },
  };
};

export default enrollmentApi;
