import { AxiosInstance } from "axios";
import {
  Assessment,
  AssessmentPost,
  AssessmentsResponse,
} from "@mindprint-learning/api-lib";

const assessments = (api: AxiosInstance) => {
  return {
    get: () => {
      return api.get<AssessmentsResponse>(`/assessments`);
    },
    getBySiteId: (siteId: number | undefined) => {
      const path = siteId ? `/?site_id=${siteId}` : "";
      return api.get<AssessmentsResponse>(`/assessments${path}`);
    },
    getPendingReview: () => {
      return api.get<AssessmentsResponse>(`/assessments/pending-review`);
    },
    create: (data: AssessmentPost) => {
      return api.post<Assessment>("/assessments", data);
    },
  };
};

export default assessments;
