import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";

import { SATACTReport } from "@mindprint-learning/design";
import { fetchSATACTReport } from "./store";

import Message from "components/Message/Message";
import PageLoader from "components/PageLoader";

const StudentSATACTReport = () => {
  const data = useAppSelector(
    (state: RootState) => state.satActReport.data?.report
  );
  const loading = useAppSelector(
    (state: RootState) => state.satActReport.loading
  );
  const hasError = useAppSelector(
    (state: RootState) => state.satActReport.hasError
  );

  const messageData = {
    header: "Data Loading Error",
    p1: "We apologize. The data did not load properly.",
    p2: "Please try again.",
    url: "/assess/sites",
  };

  const dispatch = useAppDispatch();
  const params = useParams();
  const location = useLocation(); // Get the current location object
  const path = location.pathname; // Access the path name from location

  // Check path for report type
  const reportType = path.includes("/sat-report") ? "SAT" : "ACT";

  useEffect(() => {
    const paramId = params.id ? Number(params.id) : undefined;
    dispatch(fetchSATACTReport(paramId));
  }, [dispatch, params]);

  return (
    <>
      {loading && <PageLoader />}

      {data && !hasError && !loading && (
        <SATACTReport reportType={reportType} report={data} />
      )}

      {hasError && <Message {...messageData} />}
    </>
  );
};

export default StudentSATACTReport;
