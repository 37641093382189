import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AssessmentsState } from "./types";

import toast from "react-hot-toast";

import api from "services/api";

const initialState: AssessmentsState = {
  list: undefined,
  destructive: false,
  loading: false,
};

export const fetchAll = createAsyncThunk("assessments/fetch", async () => {
  return (await api.assessments.get()).data;
});

export const fetchBySiteId = createAsyncThunk(
  "assessments/siteId",
  async (siteId: number | undefined) => {
    return (await api.assessments.getBySiteId(siteId)).data;
  }
);

export const fetchByPending = createAsyncThunk(
  "assessments/pending-review",
  async () => {
    return (await api.assessments.getPendingReview()).data;
  }
);

export const assessmentsSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {
    clear: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAll.pending, (state) => {
        state.loading = true;
        state.list = undefined;
      })
      .addCase(fetchAll.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
        state.destructive = action.payload.destructive;
      })
      .addCase(fetchAll.rejected, (state) => {
        state.loading = false;
        toast.error("Oops! There was an error fetching the assessments.", {
          position: "top-center",
        });
      })
      .addCase(fetchBySiteId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBySiteId.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
        state.destructive = action.payload.destructive;
      })
      .addCase(fetchBySiteId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchByPending.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchByPending.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
        state.destructive = action.payload.destructive;
      })
      .addCase(fetchByPending.rejected, (state) => {
        state.loading = false;
      });
      
  },
});

export const clear = assessmentsSlice.actions.clear;

export default assessmentsSlice.reducer;
