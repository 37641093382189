import { ReactNode } from "react";
import {
  Button,
  ButtonTypes,
  Modal,
  ModalTypes,
} from "@mindprint-learning/design";
import styles from "./Modal.module.css";

interface DeleteModalProps {
  show: boolean;
  message: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  type?: string;
  tableType?: string;
}

const DeleteModal = ({
  show,
  message,
  onCancel,
  onConfirm,
  type,
  title,
  tableType,
}: DeleteModalProps) => {
  return (
    <Modal
      handleClose={onCancel}
      show={show}
      type={ModalTypes.delete}
      title={title ? title : "Are you sure?"}
    >
      <div className={styles.main}>
        {message}
        <div className={styles.buttonContainer}>
          <Button
            label={"Cancel"}
            primary={true}
            type={ButtonTypes.neutral}
            onClick={() => onCancel()}
          />
          <Button
            label={<span>Delete {tableType ? tableType : ""}</span>}
            onClick={onConfirm}
            primary={true}
            type={ButtonTypes.del}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
