import { report_type, rules_categories } from "@mindprint-learning/api-lib";
import { Divider } from "@mindprint-learning/design";
import { Rule } from "services/api/rules";
import RuleBox from "./RuleBox";

const Rules = ({ data }: RulesProps) => {
  const comprehensive = data.filter(
    (rules) => rules.report_type === report_type.COMPREHENSIVE
  );
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
      <h2>Comprehensive</h2>

      <Divider />

      <h3>Complex Reasoning</h3>
      {comprehensive
        .filter(
          (value) => value.category === rules_categories.COMPLEX_REASONING
        )
        .map((rule, index) => {
          return <RuleBox key={`cr-rule-${index}`} rule={rule} />;
        })}

      <h3>Memory</h3>
      {comprehensive
        .filter((value) => value.category === rules_categories.MEMORY)
        .map((rule, index) => {
          return <RuleBox key={`memory-rule-${index}`} rule={rule} />;
        })}

      <h3>Executive Function</h3>
      {comprehensive
        .filter(
          (value) => value.category === rules_categories.EXECUTIVE_FUNCTION
        )
        .map((rule, index) => {
          return <RuleBox key={`memory-rule-${index}`} rule={rule} />;
        })}

      <h3>Processing Speed</h3>
      {comprehensive
        .filter((value) => value.category === rules_categories.PROCESSING_SPEED)
        .map((rule, index) => {
          return <RuleBox key={`memory-rule-${index}`} rule={rule} />;
        })}

      <h3>Minor</h3>
      {comprehensive
        .filter((value) => value.category === rules_categories.MINOR)
        .map((rule, index) => {
          return <RuleBox key={`memory-rule-${index}`} rule={rule} />;
        })}

      <h3>Other</h3>
      {comprehensive
        .filter((value) => value.category === rules_categories.OTHER)
        .map((rule, index) => {
          return <RuleBox key={`memory-rule-${index}`} rule={rule} />;
        })}
      <h2>Test Prep</h2>

      {data
        .filter(
          (rules) =>
            rules.report_type === report_type.TP_ACT ||
            rules.report_type === report_type.TP_SAT
        )
        .map((rule, index) => {
          return <RuleBox key={`comprehensive-rule-${index}`} rule={rule} />;
        })}
    </div>
  );
};

export default Rules;

interface RulesProps {
  data: Array<Rule>;
}

//import RuleBox from "./ui/RuleBox";
