import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SLPState } from "./types";
import api from "services/api";

const initialState: SLPState = {
  data: null,
  loading: false,
  hasError: false,
};

export const fetchSLProfile = createAsyncThunk(
  "fetchSLProfile",
  async (subid: string | undefined) => {
    return (await api.studentLearnerProfile.getStudentLearnerProfile(subid))
      .data;
  }
);

const StudentLearnerProfileSlice = createSlice({
  name: "studentLearnerProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSLProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSLProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSLProfile.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export default StudentLearnerProfileSlice.reducer;
