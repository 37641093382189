import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Organization } from "@mindprint-learning/api-lib";

import { OrganizationFormData } from "../OrganizationsPage/OrganizationForm/OrganizationForm.types";
import { OrganizationsState } from "./types";

import api from "services/api";

const initialState: OrganizationsState = {
  list: undefined,
  destructive: false,
  users: undefined,
  loading: false,
  hasError: false,
  showOrganizationForm: false,
  selectedOrganization: null,
  deletedOrganization: null,
};

export const fetchAllOrgs = createAsyncThunk(
  "organizations/fetch",
  async () => {
    return (await api.organizations.getAllOrgs()).data;
  }
);

export const fetchUsers = createAsyncThunk(
  "organizations/fetchUsers",
  async () => {
    return (await api.organizations.getUsers()).data;
  }
);

export const createOrg = createAsyncThunk(
  "organizations/create",
  async (data: OrganizationFormData) => {
    return (await api.organizations.createOrg(data)).data;
  }
);

export const updateOrg = createAsyncThunk(
  "organizations/update",
  async (data: OrganizationFormData) => {
    return (await api.organizations.updateOrg(data)).data;
  }
);

export const deleteOrg = createAsyncThunk(
  "organizations/delete",
  async (data: Organization) => {
    return (await api.organizations.deleteOrg(data)).data;
  }
);

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setShowOrganizationForm: (state, action) => {
      state.showOrganizationForm = action.payload;
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setDeletedOrganization: (state, action) => {
      state.deletedOrganization = action.payload;
    },
    clearOrgs: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrgs.pending, (state) => {
        state.loading = true;
        state.list = undefined;
      })
      .addCase(fetchAllOrgs.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
        state.destructive = action.payload.destructive;
      })
      .addCase(fetchAllOrgs.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(createOrg.pending, (state) => {
        state.loading = true;
      })
      .addCase(createOrg.fulfilled, (state, action) => {
        state.loading = false;
        state.list?.push(action.payload);
        state.selectedOrganization = null;
      })
      .addCase(createOrg.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(updateOrg.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOrg.fulfilled, (state, action) => {
        state.loading = false;

        const updatedOrg = action.payload;
        const updatedOrgIndex = state.list?.findIndex(
          (org) => org.id === updatedOrg.id
        );
        state.list![updatedOrgIndex!] = updatedOrg;
        state.selectedOrganization = null;
      })
      .addCase(updateOrg.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(deleteOrg.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteOrg.fulfilled, (state, action) => {
        state.loading = false;
        const deletedOrg = action.payload;
        state.list = state.list?.filter((org) => org.id !== deletedOrg.id);
        state.deletedOrganization = null;
      })
      .addCase(deleteOrg.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

// Actions
export const setShowOrganizationForm =
  organizationsSlice.actions.setShowOrganizationForm;
export const setSelectedOrganization =
  organizationsSlice.actions.setSelectedOrganization;
export const setDeletedOrganization =
  organizationsSlice.actions.setDeletedOrganization;
export const clearOrgs = organizationsSlice.actions.clearOrgs;

export default organizationsSlice.reducer;
