import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CognitiveTableState } from "./types";
import api from "services/api";

const initialState: CognitiveTableState = {
  data: [],
  loading: false,
  hasError: false,
};

export const fetchCognitiveTableData = createAsyncThunk(
  "fetchCognitiveTableData",
  async (siteId: number) => {
    return (await api.cognitiveTable.fetchCognitiveTableData(siteId)).data;
  }
);

const CognitiveTableSlice = createSlice({
  name: "CognitiveTable",
  initialState,
  reducers: {
    clearCognitiveTableState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCognitiveTableData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCognitiveTableData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCognitiveTableData.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export default CognitiveTableSlice.reducer;

export const clearCognitiveTableState =
  CognitiveTableSlice.actions.clearCognitiveTableState;
