import { assessments_status } from "@mindprint-learning/api-lib";
import { StickyContainer } from "@mindprint-learning/design";
import { V1Assessment } from "../../../../services/api/rules";

import styles from "./InfoContainer.module.css";

const InfoContainer = ({ profile }: InfoContainerProps) => {
  return (
    <StickyContainer top={"5.2rem"} backgroundColor={"#fff"}>
      <div className={styles.main}>
        <div className={styles.infoCell}>
          <div className={styles.infoLabel}>Name</div>
          <div>
            {profile.first_name} {profile.last_name}
          </div>
        </div>
        <div className={styles.infoCell}>
          <div className={styles.infoLabel}>V1 Customer ID</div>
          <div>{profile.customer_id}</div>
        </div>
        <div className={styles.infoCell}>
          <div className={styles.infoLabel}>V1 Family ID</div>
          <div>{profile.family_id}</div>
        </div>
        <div className={styles.infoCell}>
          <div className={styles.infoLabel}>Subid</div>
          <div>{profile.subid}</div>
        </div>
        <div className={styles.infoCell}>
          <div className={styles.infoLabel}>Age</div>
          <div>{profile.age}</div>
        </div>
        <div className={styles.infoCell}>
          <div className={styles.infoLabel}>Gender</div>
          <div>{profile.gender}</div>
        </div>
        {/* <div className={styles.infoCell}>
          <div className={styles.infoLabel}>Assessment Status</div>
          <StatusPill value={profile.status} />
        </div> */}
      </div>
    </StickyContainer>
  );
};

export default InfoContainer;

interface InfoContainerProps {
  profile: V1Assessment;
}

const StatusPill = ({ value }: SatusPillProps) => {
  return (
    <div>
      <div className={styles.statusPill}>{value}</div>
    </div>
  );
};

interface SatusPillProps {
  value: assessments_status;
}
