import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { NWEAState } from "./types";
import api from "services/api";

const initialState: NWEAState = {
  data: null,
  loading: false,
  hasError: false,
};

export const fetchNWEAReport = createAsyncThunk(
  "fetchNWEAReport",
  async (subid: string | undefined) => {
    return (await api.nweaReport.getNweaReport(subid)).data;
  }
);

const NWEAReportSlice = createSlice({
  name: "nweaReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNWEAReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNWEAReport.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchNWEAReport.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export default NWEAReportSlice.reducer;
