export const CATEGORYOPTIONS = [
  {
    label: "Strengths",
    value: "strength",
  },
  {
    label: "Needs",
    value: "need",
  },
  {
    label: "Recommendations",
    value: "recommendation",
  },
];

export const ORDEROPTIONS = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
];

export const RULELIBRARY = [
  {
    Spatial_Perception_Need: [
      {
        label: "Relative Difference in Spatial Perception",
        description:
          "{{first-name}}'s spatial perception was fine, but it was {{his-her}} lowest reasoning skill. {{first-name}} might have more difficulty when {{he-she}} needs to visualize relationships in space or with hands-on tasks. This might be most important in subjects like geometry and science.",
      },
    ],
    Spatial_Perception_Recommendation: [
      {
        label: "Use Critical Thinking on Spatial Tasks",
        description:
          "Encourage {{first-name}} to talk through spatial tasks to better make sense of what {{he-she}} sees. Providing simple pictures and having {{him-her}} use {{his-her}} stronger critical thinking to make connections to known information also will strengthen {{his-her}} understanding. Using graph paper can help {{him-her}} write and draw more clearly and accurately.",
      },
    ],
    General_Flexible_Thinking_Need: [
      {
        label: "Relative Difference in Flexible Thinking",
        description:
          "While {{first-name}}'s flexible thinking was fine, it was {{his-her}} not as good as {{his-her}} reasoning skills. {{first-name}} might sometimes have more difficulty than expected with novel problems or taking feedback and adapting to unexpected situations.",
      },
    ],
    General_Flexible_Thinking_Recommendation: [
      {
        label: "Use Complex Reasoning in Problem Solving",
        description:
          "If {{first-name}} ever struggles with novel problems or situations, coach how to use corrective feedback, consider different options, and shift approaches. Having concrete strategies can allow {{him-her}} to use {{his-her}} stronger abstract and verbal reasoning to better evaluate options and strengthen {{his-her}} decision making.",
      },
    ],
    Flexible_Thinking_Fast_Speed_Need: [
      {
        label: "Relative Difference in Flexible Thinking",
        description:
          "While {{first-name}}'s flexible thinking was good, {{he-she}} worked quickly on this task when slowing down might have resulted in better accuracy. {{first-name}} might sometimes work too quickly when faced with novel problems or taking feedback when taking a more deliberative approach might be beneficial.",
      },
    ],
    Flexible_Thinking_Fast_Speed_Recommendation: [
      {
        label: "Slow Down in Problem Solving",
        description:
          "If {{first-name}} struggles with novel problems or situations, coach how to pause before responding, use corrective feedback, consider different options, and shift approaches. Having concrete strategies can allow {{him-her}} to more deliberately evaluate options, strengthen {{his-her}} decision making, and improve {{his-her}} problem solving.",
      },
    ],
    Verbal_Memory_Need: [
      {
        label: "Relative Difference in Verbal Memory",
        description:
          "{{first-name}}'s verbal memory is good, but not as strong as {{his-her}} other skills. Since much of academic learning relies on remembering what you read and hear, you will want to be sure that {{first-name}} does not have gaps in knowledge or is taking much longer to finish work despite {{his-her}} strong capabilities.",
      },
    ],
    Verbal_Memory_Recommendation: [
      {
        label: "Use Visual Memory",
        description:
          "If {{first-name}} ever has difficulty efficiently remembering language-based information, multi-modal reinforcement (seeing, touching, speaking) will help. Encourage {{first-name}} to use {{his-her}} stronger visual memory by referring to pictures in informational texts or flashcards with pictures.",
      },
    ],
    Visual_Memory_Need: [
      {
        label: "Relative Difference in Visual Memory",
        description:
          "{{first-name}}'s visual memory is fine, but not as strong as {{his-her}} other skills. You might find that {{first-name}} has relative difficulty remembering images, graphs, formulas and other numerical information which could lead to gaps in knowledge in subjects like math which are cumulative in nature.",
      },
    ],
    Visual_Memory_Recommendation: [
      {
        label: "Use Verbal Memory",
        description:
          "If {{first-name}} ever has difficulty efficiently remembering pictures, images or numerical information, encourage {{first-name}} to use {{his-her}} stronger verbal memory by describing what {{he-she}} sees in {{his-her}} own words. Pairing pictures with words will help {{him-her}} remember more efficiently.",
      },
    ],
    Attention_Accuracy_Need: [
      {
        label: "Relative Difference in Attention",
        description:
          "{{first-name}}'s attention was fine, but not as strong as {{his-her}} other skills. {{first-name}} might sometimes require extra effort to sustain focus on longer tasks or those {{he-she}} finds less interesting.",
      },
    ],
    Attention_Accuracy_Recommendation: [
      {
        label: "Monitor Attention",
        description:
          "If you find that {{first-name}} has difficulty sustaining {{his-her}} focus on tasks {{he-she}} finds less interesting, teach {{him-her}} strategies for self-management. Tackling those tasks first when {{his-her}} energy level is highest, taking regularly scheduled breaks, and/or chunking up the task into more manageable amounts might all be good strategies for {{first-name}}.",
      },
    ],
    Attention_Fast_Speed_Need: [
      {
        label: "Relative Difference in Attention",
        description:
          "{{first-name}}'s attention accuracy was fine, but {{he-she}} did work quickly suggesting {{he-she}} might sometimes respond without fully consider {{his-her}} responses. Students who work too quickly can be prone to inconsistent performance or scattered errors.",
      },
    ],
    Attention_Fast_Speed_Recommendation: [
      {
        label: "Monitor Attention",
        description:
          "If you find that {{first-name}} is not consistently showing {{his-her}} best work, teach {{him-her}} strategies for self-management. Teaching {{him-her}} to be aware of when {{he-she}} might be working too quickly and learning strategies for how to check work in different situations might be helpful.",
      },
    ],
    Verbal_Reasoning_Need: [
      {
        label: "Relative Difference in Verbal Reasoning",
        description:
          "{{first-name}}'s verbal reasoning is good but not as strong as {{his-her}} visual reasoning skills. {{first-name}} might find that it requires more effort than {{he-she}} anticipates to understand what {{he-she}} reads while visual or mathematical concepts come more easily.",
      },
    ],
    Verbal_Reasoning_Recommendation: [
      {
        label: "Use Stronger Visual Reasoning on Verbal Tasks",
        description:
          "Encourage {{first-name}} to use {{his-her}} stronger visual reasoning if {{he-she}} experiences difficulty on language-based tasks. Visualizing what {{he-she}} reads, referring to the pictures or images, or using a graphic organizer could all be good strategies for {{first-name}}.",
      },
    ],
    Verbal_Skills_Need: [
      {
        label: "Relative Difference in Verbal Skills",
        description:
          "{{first-name}}'s verbal reasoning and memory are good but not as strong as {{his-her}} visual skills. {{first-name}} might find that it requires more effort than anticipated to understand and remember what {{he-she}} reads while visual or mathematical concepts come more easily.",
      },
    ],
    Verbal_Skills_Recommendation: [
      {
        label: "Use Stronger Visual Skills on Verbal Tasks",
        description:
          "Encourage {{first-name}} to use {{his-her}} stronger visual skills if {{he-she}} experiences difficulty on language-based tasks. Visualizing what {{he-she}} reads, using pictures, or using a graphic organizer could all be good strategies for {{first-name}}.",
      },
    ],
    Abstract_Reasoning_Need: [
      {
        label: "Relative Difference in Abstract Reasoning",
        description:
          "{{first-name}}'s abstract reasoning is good but not as strong as {{his-her}} other reasoning skills. {{first-name}} might find that it requires more effort than {{he-she}} anticipates to understand visual or abstract concepts like patterns, charts and numerical information while {{he-she}} more readily understands what {{he-she}} reads.",
      },
    ],
    Visual_Reasoning_Need: [
      {
        label: "Relative Difference in Visual Reasoning",
        description:
          "{{first-name}}'s visual reasoning is good but not as strong as {{his-her}} verbal reasoning. {{first-name}} might find that it requires more effort than {{he-she}} anticipates to understand visual, spatial or abstract concepts like patterns, charts and numerical information while {{he-she}} more readily understands what {{he-she}} reads or hears.",
      },
    ],
    Abstract_or_Visual_Reasoning_Recommendation: [
      {
        label: "Use Verbal Reasoning on Visual Tasks",
        description:
          "If {{first-name}} has difficulty understanding visual or abstract tasks, encourage {{him-her}} to use {{his-her}} stronger verbal reasoning to talk through the problem. Describing complex ideas or visuals in {{his-her}} own words will strengthen {{his-her}} understanding.",
      },
    ],
    Working_Memory_Need: [
      {
        label: "Relative Difference in Working Memory",
        description:
          "While {{first-name}}'s working memory is fine, it is {{his-her}} lowest skill. {{first-name}} might sometimes have difficulty holding information in short-term memory which could affect efficiency or accuracy on multi-step tasks if {{he-she}} does not write things down. This might include mental math, following directions, and complex problem solving.",
      },
    ],
    Working_Memory_Recommendation: [
      {
        label: "Monitor Working Memory",
        description:
          "If {{first-name}} ever has difficulty with short-term recall, encourage {{him-her}} to use checklists and visual reminders to work through tasks. Since working memory can cause scattered errors, teach {{him-her}} strategies to check {{his-her}} work so {{he-she}} demonstrates {{his-her}} full potential on tests and assignments.",
      },
    ],
    Executive_Function_lower_than_Complex_Reasoning_and_Memory_Need: [
      {
        label: "Relative Difference in Executive Functions",
        description:
          "{{first-name}}'s working memory and attention are fine, but not as strong {{his-her}} memory and complex reasoning. {{first-name}} might sometimes have more difficulty than you might otherwise expect sustaining focus or completing tasks efficiently given {{his-her}} stronger skills.",
      },
    ],
    EF_lower_than_Reasoning_and_Memory_Recommendation: [
      {
        label: "Use Strategies for Executive Functions",
        description:
          "If {{first-name}} ever has difficulty completing work efficiently or is making scattered errors, encourage {{him-her}} to use checklists and visual reminders to work through tasks. Taking scheduled breaks to sustain focus and learning how to check {{his-her}} work efficiently also might be good strategies for {{first-name}}.",
      },
    ],
    Memory_Stronger_than_Reasoning_or_EF_Need: [
      {
        label: "Relative Difference in Domains",
        description:
          "{{first-name}}'s reasoning skills are good but not as strong as {{his-her}} memory skills. {{first-name}} might need more effort to understand new material but then find {{he-she}} readily retains that information for long-term learning.",
      },
    ],
    Memory_Stronger_than_Reasoning_or_EF_Recommendation: [
      {
        label: "Relative Differences in Skills",
        description:
          "{{first-name}}'s critical thinking skills are good but not as strong as {{his-her}} memory and creative problem solving skills. {{first-name}} might need more effort to understand new material than {{he-she}} expects, but find {{he-she}} readily retains that information for long-term learning.",
      },
      {
        label: "Use Good Memory",
        description:
          "Encourage {{first-name}} to use {{his-her}} good memory for topics {{he-she}} finds challenging. {{first-name}} can efficiently memorize extra information and build a strong foundation of knowledge so {{he-she}} has more mental energy for understanding and solving challenging problems.",
      },
    ],
    Complex_Reasoning_Stronger_than_Memory_Need: [
      {
        label: "Relative Difference in Memory",
        description:
          "{{first-name}}'s memory skills are fine but not as strong as {{his-her}} reasoning skills. {{first-name}} might find {{he-she}} understands new information but needs more effort to remember new material than expected.",
      },
    ],
    Complex_Reasoning_Stronger_than_Memory_Recommendation: [
      {
        label: "Use Stronger Reasoning to Memorize",
        description:
          "Encourage {{first-name}} to use {{his-her}} stronger reasoning skills to memorize more efficiently. Making direct connections to what {{he-she}} knows, rather than relying on rote memorization, will be an important strategy for {{first-name}}. Multi-modal reinforcement (reading, speaking, visualizing, and touching) also will help {{him-her}} memorize more effectively.",
      },
    ],
    Superior_Abstract_Reasoning_Only_Need: [
      {
        label: "Relative Difference in Skills",
        description:
          "{{first-name}}'s memory and verbal reasoning skills are good but {{his-her}} abstract reasoning is superior. {{first-name}} might find {{he-she}} readily understands complex concepts but needs more effort to read or remember them than {{he-she}} expects. This could be a source of confusion or frustration for some students with superior skills.",
      },
    ],
    Superior_Abstract_Reasoning_Recommendation: [
      {
        label: "Use Stronger Visual Reasoning",
        description:
          "Encourage {{first-name}} to use {{his-her}} stronger visual reasoning if {{he-she}} experiences any difficulty on reading or writing assignments. Visualizing what {{he-she}} reads, referring to the pictures or images, or using a graphic organizer could all be good strategies for {{first-name}}. {{first-name}} might also want to avoid rote memorization and instead use {{his-her}} strong visual reasoning to make connections to previous learning. Having {{first-name}} use a stepped approach in problem solving can help {{him-her}} tackle novel problems in a manageable and thorough way.",
      },
    ],
    Variable_Speed_Need: [
      {
        label: "Variable Speed",
        description:
          "{{first-name}} showed variability in pacing, sometimes where {{he-she}} seemed to need to work slowly to maintain accuracy and sometimes seemed to work quickly when slowing down might have resulted in even better accuracy. Variability in pacing could suggest a student might benefit from a greater awareness of pacing so {{he-she}} recognizes when working more slowly might result in better accuracy and when {{he-she}} can work more quickly and still show {{his-her}} full potential.",
      },
    ],
    Fast_Speed_Need: [
      {
        label: "Faster Processing Speed",
        description:
          "{{first-name}} generally kept a good pace on tasks but sometimes worked more quickly when slowing down might have resulted in better accuracy. {{first-name}} might benefit from a greater awareness of pacing so {{he-she}} recognizes when working more slowly might result in better accuracy.",
      },
    ],
    Variable_or_Fast_Speed_Recommendation: [
      {
        label: "Develop Awareness of Pacing",
        description:
          "If you find that {{first-name}} is not living up to {{his-her}} full potential, provide {{him-her}} strategies to manage {{his-her}} pace, including using a visual timer so {{he-she}} can recognize when {{he-she}} might be working too quickly. Encouraging {{him-her}} to check {{his-her}} work and teaching {{him-her}} how to do so also will be helpful.",
      },
    ],
    Slow_Speed_Need: [
      {
        label: "Relatively Slower Processing Speed",
        description:
          "{{first-name}}'s processing speed is fine and well within the expected range. However, given {{his-her}} complex reasoning and memory skills, it might sometimes feel that tasks can take longer than expected, particularly on standardized tests. If this is the case for {{first-name}}, it might be helpful to teach {{first-name}} strategies for balancing accuracy and speed.",
      },
    ],
    Slow_Speed_Recommendation: [
      {
        label: "Develop Awareness of Pacing",
        description:
          "If you find that {{first-name}} is not living up to {{his-her}} full potential, particularly on timed tests, provide {{him-her}} strategies to be more aware of {{his-her}} pace. {{first-name}} could use a visual timer so {{he-she}} can recognize when {{he-she}} might be able to work more quickly and when {{he-she}} can take all the time {{he-she}} needs.",
      },
    ],
  },
];
