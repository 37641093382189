import { useState } from "react";
import { FaCopy } from "react-icons/fa6";
import {
  assessments_status,
  assessments_type,
  User,
} from "@mindprint-learning/api-lib";
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  CellTypes,
  Divider,
  Modal,
  ModalTypes,
  Table,
} from "@mindprint-learning/design";
import { PageSection, PageSubsection } from "components/PageSection";
import { createAssessment } from "modules/UserDetail/store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";

import styles from "./Assessments.module.css";

const Assessments = ({ user, sectionRef, onInView }: AssessmentsProps) => {
  const dispatch = useAppDispatch();
  const assessments = useAppSelector(
    (state: RootState) => state.userDetail.assessments
  );
  const hasActive =
    (
      assessments?.filter(
        (assessment: any) => assessment.status === assessments_status.assigned
      ) || []
    ).length > 0;

  const destructive = useAppSelector(
    (state: RootState) => state.userDetail.destructive
  );

  const [selectedAssessment, setSelectedAssessment] = useState<
    Object | undefined
  >(undefined);

  const [showAssign, setShowAssign] = useState(false);

  console.log("assessments=", assessments);
  return (
    <PageSection
      title="Assessments"
      sectionRef={sectionRef}
      onInView={onInView}
    >
      <Modal
        title={hasActive ? "Oops!" : "Assign an Assessment"}
        show={showAssign}
        handleClose={() => setShowAssign(false)}
        type={hasActive ? ModalTypes.warning : undefined}
      >
        <div className={styles.modalContent}>
          <h3>MindPrint Comprehensive Assessment</h3>
          <Divider />
          {hasActive ? (
            <div>{`This assessment has already been assigned to ${user.first_name}. A student can only have one active assessment at a time.`}</div>
          ) : (
            <div className={styles.buttonContainer}>
              <Button
                label={"Cancel"}
                primary={false}
                onClick={() => {
                  setShowAssign(false);
                }}
              />
              <Button
                label={`Assign to ${user.first_name}`}
                primary={true}
                onClick={() => {
                  setShowAssign(false);
                  dispatch(
                    createAssessment({
                      user_id: user.id,
                      type: assessments_type.MINDPRINT_SURVEY_V2,
                    })
                  );
                }}
              />
            </div>
          )}
        </div>
      </Modal>
      <Modal
        show={selectedAssessment !== undefined}
        handleClose={() => setSelectedAssessment(undefined)}
        title="View Assessment"
      >
        <div className={styles.copyContainer}>
          <div className={styles.copyBox}>
            {/* <div>{`https://assess.mindprintlearning.com/${selectedAssessment["hash"]}`}</div> */}
          </div>
          <Button
            size={ButtonSizes.small}
            type={ButtonTypes.neutral}
            primary={true}
            label={
              <span className={styles.buttonText}>
                <FaCopy />
                <p>Copy</p>
              </span>
            }
            onClick={() => console.log("copy...")}
          />
        </div>
      </Modal>
      <PageSubsection title="" description="View and modify assessments">
        <Table
          pageSize={5}
          addText="Assign an Assessment"
          add={destructive ? () => setShowAssign(true) : undefined}
          rowClick={(row) => setSelectedAssessment(row)}
          headers={[
            { label: "Status", accessor: "status", type: CellTypes.status },
            {
              label: "Assigned",
              accessor: "created_at",
              type: CellTypes.date,
            },
            {
              label: "Progress",
              accessor: "progress",
              type: CellTypes.progress,
            },
          ]}
          rows={assessments}
        />
      </PageSubsection>
    </PageSection>
  );
};

export default Assessments;

interface AssessmentsProps {
  user: User;
  sectionRef?: any;
  onInView?: () => void;
}
