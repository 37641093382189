import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";

import { SATVSACTReport } from "@mindprint-learning/design";
import { fetchSATVSACTReport } from "./store";

import Message from "components/Message/Message";
import PageLoader from "components/PageLoader";

const StudentSATVSACTReport = () => {
  const data = useAppSelector(
    (state: RootState) => state.satVsActReport.data?.report
  );
  const loading = useAppSelector(
    (state: RootState) => state.satVsActReport.loading
  );
  const hasError = useAppSelector(
    (state: RootState) => state.satVsActReport.hasError
  );

  const messageData = {
    header: "Data Loading Error",
    p1: "We apologize. The data did not load properly.",
    p2: "Please try again.",
    url: "/assess/sites",
  };

  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    const paramId = params.id ? Number(params.id) : undefined;
    dispatch(fetchSATVSACTReport(paramId));
  }, [dispatch, params]);

  return (
    <>
      {loading && <PageLoader />}

      {data && !hasError && !loading && <SATVSACTReport report={data} />}

      {hasError && <Message {...messageData} />}
    </>
  );
};

export default StudentSATVSACTReport;
