import { useEffect, useCallback } from "react";
import { BrowserRouter, useSearchParams } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "store/hooks";
import { RootState } from "store/store";
import { verify, getUserOrg, getUserSites } from "modules/Auth/store";

import Routes from "./routes/Routes";
import PageLoader from "components/PageLoader";

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default AppWrapper;

const App = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const loading = useAppSelector((state: RootState) => state.auth.loading);

  const token = searchParams.get("t");

  if (token) {
    localStorage.setItem("token", token);
  }

  // useCallback uses memoization to ensure this is called once
  const handleVerify = useCallback(async () => {
    try {
      const verifyResult = await dispatch(verify()).unwrap();
      const loggedInUser = verifyResult.user;

      await dispatch(getUserOrg(loggedInUser.id));
      await dispatch(getUserSites(loggedInUser.id));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    handleVerify();
  }, [handleVerify]);

  return <>{loading ? <PageLoader /> : <Routes />}</>;
};
