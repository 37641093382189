import { AxiosInstance } from "axios";
import { NWEAReportProps } from "@mindprint-learning/design";

const nweaReportApi = (api: AxiosInstance) => {
  const url = window.location.pathname;
  const mathUrl = "nwea-math-report";

  return {
    getNweaReport: (subid: string | undefined) => {
      if (url.includes(mathUrl)) {
        const path = subid ? `reports/map-math/${subid}` : "";
        return api.get<NWEAReportProps>(`${path}`);
      } else {
        const path = subid ? `reports/map-reading/${subid}` : "";
        return api.get<NWEAReportProps>(`${path}`);
      }
    },
  };
};

export default nweaReportApi;
