import { useNavigate } from "react-router-dom";
import { Container, Button, ButtonTypes } from "@mindprint-learning/design";
import { MessageObj } from "./Message.types";
import "./Message.scss";

const Message = ({ header, p1, p2, url }: MessageObj) => {
  const navigate = useNavigate();

  function handleOnClick() {
    if (typeof url === "number") {
      navigate(url);
    } else {
      navigate(`${url}`);
    }
  }

  return (
    <div className="message-container">
      <Container>
        <div className="text">
          <h2>{header}</h2>
          <p>{p1}</p>
          <p>{p2}</p>
        </div>
        <div className="message-button">
          <Button
            type={ButtonTypes.ok}
            primary={true}
            label="OK"
            onClick={handleOnClick}
          />
        </div>
      </Container>
    </div>
  );
};

export default Message;
