import { MindPrintProfile } from "@mindprint-learning/design";
import PageLoader from "components/PageLoader";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { clearReport, fetchV2Profile } from "../store";

const ReportPreview = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const v2Report = useAppSelector((state: RootState) => state.rules.v2Report);

  useEffect(() => {
    if (params.subid) {
      dispatch(fetchV2Profile(params.subid));
    }
    return () => {
      dispatch(clearReport());
    };
  }, [params.subid, dispatch]);
  return (
    <>
      <>{v2Report ? <MindPrintProfile data={v2Report} /> : <PageLoader />}</>
      {/* {v2Report && (
        <pre style={{ fontFamily: "monospace", fontSize: "0.875em" }}>
          {JSON.stringify(v2Report, null, 2)}
        </pre>
      )} */}
    </>
  );
};

export default ReportPreview;
