import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { QuestionnaireState, Response } from "./types";
import api from "services/api";

const initialState: QuestionnaireState = {
  data: null,
  loading: false,
  submitted: false,
  hasError: false,
};

// Todo: remove hasError and create loadingError and submitError
// How to handle submitError? Redirect or leave user at questionnaire?

export const fetchQuestionnaire = createAsyncThunk(
  "fetchQuestionnaire",
  async () => {
    return (await api.questionnaire.getQuestionnaire()).data;
  }
);

export const submitQuestionnaire = createAsyncThunk(
  "submitQuestionnaire",
  async (data: Response) => {
    return (await api.questionnaire.submit(data)).data;
  }
);

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestionnaire.pending, (state) => {
        state.loading = true;
        state.data = null;
      })
      .addCase(fetchQuestionnaire.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchQuestionnaire.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(submitQuestionnaire.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitQuestionnaire.fulfilled, (state, action) => {
        state.loading = false;
        // @ts-ignore
        state.data = action.payload;
        state.submitted = true;
      })
      .addCase(submitQuestionnaire.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export default questionnaireSlice.reducer;
