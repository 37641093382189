import {
  Section,
  SectionsReponse,
  UsersResponse,
} from "@mindprint-learning/api-lib";
import { SectionFormData } from "modules/Sections/SectionsPage/SectionForm/SectionForm.types";
import {
  SectionEnrollData,
  SectionEnrollResponse,
} from "modules/Sections/store/types";
import { AxiosInstance } from "axios";

const sections = (api: AxiosInstance) => {
  return {
    getUsersSections: (userId: number) => {
      const params = {
        include: "counts",
        pageSize: 4000,
      };
      return api.get<SectionsReponse>(`users/${userId}/sections`, { params });
    },
    getSectionUsers: (sectionId: number | undefined) => {
      const includeParams = ["reports", "assessment-progress"];
      const params = {
        include: includeParams.join(","),
        pageSize: 4000,
      };
      const path = sectionId ? `/sections/${sectionId}` : "";
      return api.get<UsersResponse>(`${path}/users`, { params });
    },
    getSiteSections: (siteId: number | undefined) => {
      const path = siteId ? `/sites/${siteId}` : "";
      return api.get<SectionsReponse>(`${path}/sections`);
    },
    createSection: (data: SectionFormData) => {
      return api.post<Section>(`sections`, data);
    },
    updateSection: (data: SectionFormData) => {
      return api.put<Section>(`sections/${data.id}`, data);
    },
    deleteSection: (data: Section) => {
      return api.delete<Section>(`sections/${data.id}`);
    },
    getUsers: (siteId: number | undefined) => {
      const path = siteId ? `/sites/${siteId}` : "";
      return api.get<UsersResponse>(`${path}/users`);
    },
    enrollTeacherSection: (data: SectionEnrollData) => {
      return api.post<SectionEnrollResponse>(`enrollments/sections`, data);
    },
  };
};

export default sections;
