import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export const randomString = (length: number) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const date = (value: Date) => {
  const day = dayjs;
  day.extend(relativeTime);

  return dayjs(dayjs()).diff(value, "day") > 4
    ? day(value).format("M/DD/YYYY")
    : dayjs(value).fromNow();
};
