import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SATACTState } from "./types";
import api from "services/api";
import { SATACTReportProps } from "@mindprint-learning/design/build/templates/SATACTReport/SATACTReport.types";

const initialState: SATACTState = {
  data: null,
  loading: false,
  hasError: false,
};

export const fetchSATACTReport = createAsyncThunk(
  "fetchSATACTReport",
  async (id: number | undefined) => {
    const response = await api.satActReport.getSatActReport(id);
    return response.data as SATACTReportProps;
  }
);

const SATACTSlice = createSlice({
  name: "satVsActReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSATACTReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSATACTReport.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSATACTReport.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export default SATACTSlice.reducer;
