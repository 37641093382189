import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import loadable from "@loadable/component";
import { users_role } from "@mindprint-learning/api-lib";

const TeacherTrialPage = loadable(
  () => import("modules/TeacherTrial/components/TeacherTrialPage")
);

const TeacherTrials = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);

  return (
    <>
      {user && user.role === users_role.god_admin && (
        <>
          <Routes>
            <Route path="" element={<TeacherTrialPage />} />
          </Routes>
        </>
      )}
    </>
  );
};

export default TeacherTrials;
