// will convert "student" to "Student"
// and "org_admin" to "Org Admin"

function toTitleCase(text: string) {
  return text
    .split(/_|\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export default toTitleCase;
