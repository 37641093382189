import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "store/store";

import { users_role } from "@mindprint-learning/api-lib";
import { UsersState } from "./types";
import { NewUser } from "modules/Users/components/UserForm/UserForm.types";
import { User, Student } from "modules/Users/Types/User.types";

import toTitleCase from "utils/toTitleCase";

import api from "services/api";

const initialState: UsersState = {
  loading: false,
  hasError: false,
  list: undefined,
  siteUsers: undefined,
  sectionUsers: undefined,
  adminUsers: undefined,
  showCreateUser: false,
  selectedUser: null,
  deletedUser: null,
  assessments: null,
};

export const fetchAdminUsers = createAsyncThunk(
  "users/fetchAdminUsers",
  async (orgId: number) => {
    return (await api.users.getAdminUsers(orgId)).data;
  }
);
export const fetchSiteUsers = createAsyncThunk(
  "users/fetchSiteUsers",
  async (siteId: number) => {
    return (await api.users.getSitesUsers(siteId)).data;
  }
);

export const fetchSectionUsers = createAsyncThunk(
  "users/fetchSectionUsers",
  async (sectionId: number) => {
    return (await api.sections.getSectionUsers(sectionId)).data;
  }
);

export const fetchStudent = createAsyncThunk(
  "student/get",
  async (userId: number) => {
    return (await api.users.getStudent(userId)).data;
  }
);

export const createSiteUser = createAsyncThunk(
  "user/createSiteUser",
  async (data: NewUser) => {
    return (await api.users.create(data)).data;
  }
);

export const createAdminUser = createAsyncThunk(
  "user/createAdminUser",
  async (data: NewUser) => {
    return (await api.users.create(data)).data;
  }
);

export const createSectionUser = createAsyncThunk(
  "user/createSectionUser",
  async (data: NewUser) => {
    return (await api.users.create(data)).data;
  }
);

export const updateSiteUser = createAsyncThunk(
  "user/updateSiteUser",
  async (data: any) => {
    return (await api.users.update(data)).data;
  }
);

export const updateSectionUser = createAsyncThunk(
  "user/updateSectionUser",
  async (data: any) => {
    return (await api.users.update(data)).data;
  }
);

export const updateStudent = createAsyncThunk(
  "user/updateStudent",
  async (data: Student) => {
    return (await api.users.updateStudent(data)).data;
  }
);

export const updateAdminUser = createAsyncThunk(
  "user/updateAdminUser",
  async (data: any) => {
    return (await api.users.update(data)).data;
  }
);

export const deleteSiteUser = createAsyncThunk(
  "user/deleteSiteUser",
  async (data: User) => {
    return (await api.users.delete(data)).data;
  }
);

export const deleteSectionUser = createAsyncThunk(
  "user/deleteSectionUser",
  async (data: User) => {
    return (await api.users.delete(data)).data;
  }
);

export const deleteStudent = createAsyncThunk(
  "user/deleteStudent",
  async (data: User) => {
    return (await api.users.deleteStudent(data)).data;
  }
);

export const deleteAdminUser = createAsyncThunk(
  "user/deleteAdminUser",
  async (data: User) => {
    return (await api.users.delete(data)).data;
  }
);

// export const fetchAssessments = createAsyncThunk(
//   "assessments/fetch",
//   async (studentId: number) => {
//     return (await api.users.getAssessments(studentId)).data;
//   }
// );

// export const createAssessment = createAsyncThunk(
//   "assessments/create",
//   async (data: any) => {
//     return (await api.assessments.create(data)).data;
//   }
// );

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setShowCreateUser: (state, action) => {
      state.showCreateUser = action.payload;
    },
    setDeletedUser: (state, action) => {
      state.deletedUser = action.payload;
    },
    addUsersToSection: (state, action) => {
      const newUsersArray = action.payload;
      newUsersArray.forEach((user: User) => {
        state.sectionUsers?.push(user);
      });
    },
    clearSiteUsers: (state) => {
      state.siteUsers = initialState.siteUsers;
    },
    clearSectionUsers: (state) => {
      state.sectionUsers = initialState.sectionUsers;
    },
    clearAdminUsers: (state) => {
      state.adminUsers = initialState.adminUsers;
    },
    resetError: (state) => {
      state.hasError = false;
    },
    resetUserState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAdminUsers.fulfilled, (state, action) => {
        state.loading = false;

        state.adminUsers = action.payload.data.map((user: User) => {
          user.role = toTitleCase(user.role) as users_role;
          return user;
        });
      })
      .addCase(fetchAdminUsers.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(fetchSiteUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSiteUsers.fulfilled, (state, action) => {
        state.loading = false;

        state.siteUsers = action.payload.data.map((user: User) => {
          user.role = toTitleCase(user.role) as users_role;

          // const percent = user.included?.assessments.latest.percent ?? 0;
          // const completeDate = user.included?.assessments.latest.completeDate;

          const percent =
            user.included?.latestAssessment?.progress?.percent ?? 0;
          const completeDate =
            user.included?.latestAssessment?.progress?.completeDate;

          user.progress = {
            percent: percent / 100 || 0,
            completeDate: completeDate || null,
          };

          return user;
        });
      })
      .addCase(fetchSiteUsers.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(fetchSectionUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSectionUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.sectionUsers = action.payload.data.map((user: User) => {
          user.role = toTitleCase(user.role) as users_role;

          const percent =
            user.included?.latestAssessment?.progress?.percent ?? 0;
          const completeDate =
            user.included?.latestAssessment?.progress?.completeDate;

          user.progress = {
            percent: percent / 100 || 0,
            completeDate: completeDate || null,
          };

          return user;
        });
      })
      .addCase(fetchSectionUsers.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(fetchStudent.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStudent.fulfilled, (state, action) => {
        state.loading = false;
        const student = action.payload;

        student.role = toTitleCase(student.role) as users_role;

        let foundStudent;

        // look in section users 1st
        if (state.sectionUsers && state.sectionUsers.length > 0) {
          foundStudent = state.sectionUsers.find(
            (user) => user.id === student.id
          );
        } else {
          // then look in site users
          foundStudent = state.siteUsers?.find(
            (user) => user.id === student.id
          );
        }

        state.selectedUser = student;

        // add 'progress' property on student from found student
        state.selectedUser.progress = foundStudent?.progress;
      })
      .addCase(fetchStudent.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(createAdminUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAdminUser.fulfilled, (state, action) => {
        state.loading = false;
        const newAdminUser = action.payload;
        newAdminUser.role = toTitleCase(newAdminUser.role);
        state.adminUsers?.push(newAdminUser);
      })
      .addCase(createAdminUser.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(createSiteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSiteUser.fulfilled, (state, action) => {
        state.loading = false;
        const newSiteUser = action.payload;
        newSiteUser.role = toTitleCase(newSiteUser.role);
        state.siteUsers?.push(newSiteUser);
      })
      .addCase(createSiteUser.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(createSectionUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSectionUser.fulfilled, (state, action) => {
        state.loading = false;
        const newSectionUser = action.payload;
        newSectionUser.role = toTitleCase(newSectionUser.role);
        // add new user to Section Table
        state.sectionUsers?.push(newSectionUser);

        // add new user to Site Table
        state.siteUsers?.push(newSectionUser);
      })
      .addCase(createSectionUser.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(updateAdminUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdminUser.fulfilled, (state, action) => {
        state.loading = false;

        const updatedUser = action.payload;
        updatedUser.role = toTitleCase(updatedUser.role);

        const adminUserIndex = state.adminUsers?.findIndex(
          (user) => user.id === updatedUser.id
        );
        // we can't replace the siteUser with updatedUser,
        // else we lose the 'include' (reports & assessment progress) data
        state.adminUsers![adminUserIndex!].first_name = updatedUser.first_name;
        state.adminUsers![adminUserIndex!].last_name = updatedUser.last_name;
        state.adminUsers![adminUserIndex!].email = updatedUser.email;
        state.adminUsers![adminUserIndex!].role = updatedUser.role;
        state.adminUsers![adminUserIndex!].updated_at = updatedUser.updated_at;

        state.selectedUser = null;
      })
      .addCase(updateAdminUser.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(updateSiteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSiteUser.fulfilled, (state, action) => {
        state.loading = false;

        const updatedUser = action.payload;
        updatedUser.role = toTitleCase(updatedUser.role);

        const siteUserIndex = state.siteUsers?.findIndex(
          (user) => user.id === updatedUser.id
        );
        // we can't replace the siteUser with updatedUser,
        // else we lose the 'include' (reports & assessment progress) data
        state.siteUsers![siteUserIndex!].first_name = updatedUser.first_name;
        state.siteUsers![siteUserIndex!].last_name = updatedUser.last_name;
        state.siteUsers![siteUserIndex!].email = updatedUser.email;
        state.siteUsers![siteUserIndex!].role = updatedUser.role;
        state.siteUsers![siteUserIndex!].updated_at = updatedUser.updated_at;

        state.selectedUser = null;
      })
      .addCase(updateSiteUser.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(updateSectionUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSectionUser.fulfilled, (state, action) => {
        state.loading = false;

        const updatedUser = action.payload;
        updatedUser.role = toTitleCase(updatedUser.role);

        // update Section User Table
        const sectionUserIndex = state.sectionUsers?.findIndex(
          (user) => user.id === updatedUser.id
        );
        // we can't replace the sectionUser with updatedUser,
        // else we lose the 'include' (reports & assessment progress) data
        state.sectionUsers![sectionUserIndex!].first_name =
          updatedUser.first_name;
        state.sectionUsers![sectionUserIndex!].last_name =
          updatedUser.last_name;
        state.sectionUsers![sectionUserIndex!].email = updatedUser.email;
        state.sectionUsers![sectionUserIndex!].role = updatedUser.role;
        state.sectionUsers![sectionUserIndex!].updated_at =
          updatedUser.updated_at;

        // Update Site User Table
        const siteUserIndex = state.siteUsers?.findIndex(
          (user) => user.id === updatedUser.id
        );
        // findIndex returns -1 if nothing is found
        if (siteUserIndex !== -1) {
          state.siteUsers![siteUserIndex!].first_name = updatedUser.first_name;
          state.siteUsers![siteUserIndex!].last_name = updatedUser.last_name;
          state.siteUsers![siteUserIndex!].email = updatedUser.email;
          state.siteUsers![siteUserIndex!].role = updatedUser.role;
          state.siteUsers![siteUserIndex!].updated_at = updatedUser.updated_at;
        }
        state.selectedUser = null;
      })
      .addCase(updateSectionUser.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(updateStudent.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStudent.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(deleteAdminUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAdminUser.fulfilled, (state, action) => {
        state.loading = false;
        const deletedUser = action.payload;
        state.adminUsers = state.adminUsers?.filter(
          (user) => user.id !== deletedUser.id
        );
        state.deletedUser = null;
        state.selectedUser = null;
      })
      .addCase(deleteAdminUser.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(deleteSiteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSiteUser.fulfilled, (state, action) => {
        state.loading = false;
        const deletedUser = action.payload;
        state.siteUsers = state.siteUsers?.filter(
          (user) => user.id !== deletedUser.id
        );
        state.deletedUser = null;
        state.selectedUser = null;
      })
      .addCase(deleteSiteUser.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(deleteSectionUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSectionUser.fulfilled, (state, action) => {
        state.loading = false;
        // delete from Section User Table
        const deletedUser = action.payload;
        state.sectionUsers = state.sectionUsers?.filter(
          (user) => user.id !== deletedUser.id
        );

        // delete from Site User Table
        state.siteUsers = state.siteUsers?.filter(
          (user) => user.id !== deletedUser.id
        );

        state.deletedUser = null;
        state.selectedUser = null;
      })
      .addCase(deleteSectionUser.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(deleteStudent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteStudent.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
    // .addCase(fetchAssessments.fulfilled, (state, action) => {
    //   state.assessments = action.payload;
    // });
  },
});

// Actions
export const {
  setSelectedUser,
  setShowCreateUser,
  setDeletedUser,
  addUsersToSection,
  clearSiteUsers,
  clearSectionUsers,
  clearAdminUsers,
  resetError,
  resetUserState,
} = usersSlice.actions;

export default usersSlice.reducer;

// Selectors
export const getFormattedStudent = createSelector(
  (state: RootState) => state.users.selectedUser,
  (selectedUser) => {
    if (selectedUser && selectedUser.student && selectedUser.student.dob) {
      const formattedDob = formatDate(selectedUser.student.dob);
      return {
        ...selectedUser,
        student: {
          ...selectedUser.student,
          dob: formattedDob,
        },
      };
    }
    return selectedUser;
  }
);

export const getSiteUsersNoAdmins = createSelector(
  (state: RootState) => state.users.siteUsers,
  (siteUsers) =>
    siteUsers?.filter(
      (user) => user.role === "Teacher" || user.role === "Student"
    )
);

export const getSiteUsersWithSiteAdmins = createSelector(
  (state: RootState) => state.users.siteUsers,
  (siteUsers) =>
    siteUsers?.filter(
      (user) =>
        user.role === "Site Admin" ||
        user.role === "Teacher" ||
        user.role === "Student"
    )
);

const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
