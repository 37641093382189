import { CustomRuleType } from "modules/Rules/RulesPage/ui/modals/CustomRule";
import { CombinedRuleObj } from "modules/Rules/RulesPage/ui/ClinicianSummary";

export const combineSortRules = (data: any, customRules: CustomRuleType[]) => {
  const combinedRules: CombinedRuleObj = {
    strengths: [],
    needs: [],
    recommendations: [],
  };

  function pushRulesByType(rule: any, combinedRules: CombinedRuleObj) {
    if (rule?.type === "strength") {
      combinedRules.strengths.push(rule);
    } else if (rule?.type === "need") {
      combinedRules.needs.push(rule);
    } else if (rule?.type === "recommendation") {
      combinedRules.recommendations.push(rule);
    }
  }

  // add custom rules to combined rules obj
  customRules.forEach((rule) => {
    pushRulesByType(rule, combinedRules);
  });

  // add engine rules to combined rules obj
  // iterate over each property in 'data'
  for (const category in data) {
    if (Array.isArray(data[category])) {
      data[category].forEach((rule: any) => {
        pushRulesByType(rule, combinedRules);
      });
    }
  }

  // sort each category
  combinedRules.strengths.sort((a, b) => a.order - b.order);
  combinedRules.needs.sort((a, b) => a.order - b.order);
  combinedRules.recommendations.sort((a, b) => a.order - b.order);

  return combinedRules;
};
