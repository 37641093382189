import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

import { LiftState, LiftUpdateQuery } from "./types";
import api from "services/api";
import { lifts } from "@mindprint-learning/api-lib";

const initialState: LiftState = {
  creating: false,
  editing: false,
  deleting: false,
  updating: false,
  loading: false,
  lifts: [],
  current: {} as lifts,
};

export const createLift = createAsyncThunk(
  "lift/create",
  async (lift: lifts) => {
    const response = await api.lifts.create(lift);

    return response.data;
  }
);

export const updateLift = createAsyncThunk(
  "lift/update",
  async ({ id, lift }: LiftUpdateQuery) => {
    const response = await api.lifts.update(id, lift);

    return response.data;
  }
);

export const deleteLift = createAsyncThunk(
  "lift/delete",
  async (id: number) => {
    const response = await api.lifts.delete(id);

    return response.data;
  }
);

export const fetchLifts = createAsyncThunk("lift/fetchAll", async () => {
  console.log("fetchLifts");
  const response = await api.lifts.fetch();

  return response.data;
});

export const fetchLift = createAsyncThunk(
  "lift/fetch",
  async (id: string | number) => {
    const response = await api.lifts.get(id);

    return response.data;
  }
);

export const liftSlice = createSlice({
  name: "lift",
  initialState,
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLift.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLift.fulfilled, (state, action) => {
        state.loading = false;
        console.log("LIFTS:action.payload =", action.payload);
        state.current = action.payload;
      })
      .addCase(fetchLift.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createLift.pending, (state) => {
        state.creating = true;
      })
      .addCase(createLift.fulfilled, (state, action) => {
        state.creating = false;
        state.lifts = [...state.lifts, action.payload];
        toast.success(`Successfully created ${action.payload.title}`);
      })
      .addCase(createLift.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateLift.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateLift.fulfilled, (state, action) => {
        state.updating = false;
        state.lifts = state.lifts.map((lift) => {
          if (lift.id === action.payload.id) {
            return action.payload;
          }
          return lift;
        });
        toast.success(`Successfully updated ${action.payload.title}`);
      })
      .addCase(updateLift.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteLift.pending, (state) => {
        state.deleting = true;
      })
      .addCase(deleteLift.fulfilled, (state, action) => {
        state.deleting = false;
        state.lifts = state.lifts.filter(
          (lift) => lift.id !== action.payload.id
        );
        toast.success(`Successfully deleted ${action.payload.title}`);
      })
      .addCase(deleteLift.rejected, (state) => {
        state.deleting = false;
      })
      .addCase(fetchLifts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLifts.fulfilled, (state, action) => {
        state.loading = false;
        state.lifts = action.payload;
      })
      .addCase(fetchLifts.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const setCurrent = liftSlice.actions.setCurrent;
export const setEditing = liftSlice.actions.setEditing;

export default liftSlice.reducer;
