import { CellTypes, Table } from "@mindprint-learning/design";
import { PageSection, PageSubsection } from "components/PageSection";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";

// import styles from "./Reports.module.css";

const Reports = ({ sectionRef, onInView }: ReportsProps) => {
  const user = useAppSelector((state: RootState) => state.userDetail.user);

  return (
    <PageSection title="Reports" sectionRef={sectionRef} onInView={onInView}>
      <PageSubsection
        title=""
        description={`View archived reports from previous years.`}
      >
        <Table
          headers={[
            { label: "Report Name", accessor: "name", type: CellTypes.string },
            { label: "Status", accessor: "status", type: CellTypes.status },
            { label: "Created", accessor: "created", type: CellTypes.date },
          ]}
          rows={[]}
        />
      </PageSubsection>
    </PageSection>
  );
};

export default Reports;

interface ReportsProps {
  sectionRef?: any;
  onInView?: () => void;
}

/*

Academic Profile
Mindprint Profile
Student Learner Profile
ACT Report
SAT Report

*/
