import { Container, SimpleLoader } from "@mindprint-learning/design";

import styles from "./PageLoader.module.css";

const PageLoader = () => {
  return (
    <Container>
      <div className={styles.main}>
        <SimpleLoader />
      </div>
    </Container>
  );
};

export default PageLoader;
