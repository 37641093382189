import { useEffect, useState } from "react";
import { ProfileCircle } from "@mindprint-learning/design";
import { User } from "modules/Users/Types/User.types";

const UserProfileCircle = ({ user, size, onClick }: UserProfileCircleProps) => {
  const [imgUrl, setImgUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!user || !user.profile_image) return;

    if (user.profile_image.url) {
      setImgUrl(
        `${process.env.REACT_APP_AWS_S3_BASE}${user.profile_image.url}`
      );
    } else if (user.profile_image.defaultImage) {
      setImgUrl(
        `${process.env.REACT_APP_AWS_S3_BASE}profiles/default/${user.profile_image.defaultImage}.png`
      );
    } else {
      setImgUrl(null);
    }
  }, [user]);
  return (
    <>
      {user.profile_image && (
        <ProfileCircle
          size={size}
          backgroundColor={user.profile_image.background || "#f27277"}
          initials={`${user.first_name && user.first_name[0].toUpperCase()}${
            user.last_name && user.last_name[0].toUpperCase()
          }`}
          imageSource={imgUrl}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default UserProfileCircle;

interface UserProfileCircleProps {
  user: User;
  size: number;
  onClick?: () => void;
}
