import { useState } from "react";
import { FaPlus, FaBan } from "react-icons/fa6";
import { reports_flags_types } from "@mindprint-learning/api-lib";
import {
  Button,
  ButtonTypes,
  Label,
  Modal,
  Select,
  TextArea,
} from "@mindprint-learning/design";

import styles from "./modals.module.css";

const AddFlag = ({ show, close, add }: AddFlagProps) => {
  const [description, setDescription] = useState("");
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(-1);

  const flagOptions: Array<Flag> = [
    {
      label: "General Issue",
      type: reports_flags_types.review_general,
      placeholder: "Please be as descriptive as possible",
    },
    {
      label: "Rule Match Issue",
      type: reports_flags_types.review_rule_match_issue,
      placeholder: "Please include the id of the rule in question.",
    },
    {
      label: "Scoring Issue",
      type: reports_flags_types.review_score_issue,
      placeholder: "Please describe what is incorrect with the scoring.",
    },
    {
      label: "Text Change Required",
      type: reports_flags_types.review_text_change_required,
      placeholder:
        "Please note the incorrect text, what it needs to be changed to, and whether or not it should apply to just this student or in general",
    },
    {
      label: "Strength Issue",
      type: reports_flags_types.rule_template_strengths_issue,
      placeholder:
        "Please note the Strength Issue, what it needs to be changed to, and whether or not it should apply to just this student or in general",
    },
    {
      label: "Needs Issue",
      type: reports_flags_types.rule_template_needs_issue,
      placeholder:
        "Please note the Needs Issue, what it needs to be changed to, and whether or not it should apply to just this student or in general",
    },
    {
      label: "Recommendation Issue",
      type: reports_flags_types.rule_template_recommendations_issue,
      placeholder:
        "Please note the Recommendation Issue, what it needs to be changed to, and whether or not it should apply to just this student or in general",
    },
    {
      label: "Other Issue",
      type: reports_flags_types.other_issue,
      placeholder: "Please be as descriptive as possible",
    },
  ];

  const resetAndClose = () => {
    setDescription("");
    setSelectedTypeIndex(-1);
    close();
  };
  return (
    <Modal
      show={show}
      handleClose={() => resetAndClose()}
      title="Add a Flag to This Report"
    >
      <div className={`${styles.main} ${styles.maxWidth700}`}>
        <div>
          <Label htmlFor="addFlag_select_flagType">Flag Type</Label>
          <Select
            id="addFlag_select_flagType"
            options={flagOptions.map((value) => {
              return {
                label: value.label,
              };
            })}
            onChange={(value) => setSelectedTypeIndex(value)}
            selectedIndex={selectedTypeIndex}
          />
        </div>
        <div>
          <Label htmlFor="addFlag_textArea_description">Description</Label>
          <TextArea
            id="addFlag_textArea_description"
            value={description}
            onChange={(value) => setDescription(value)}
            placeholder={
              selectedTypeIndex > -1
                ? flagOptions[selectedTypeIndex].placeholder
                : "Select a flag type above"
            }
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            label={
              <span className={styles.buttonText}>
                <FaPlus />
                <p>Add Flag</p>
              </span>
            }
            type={ButtonTypes.ok}
            onClick={() => {
              const selectedOption = flagOptions[selectedTypeIndex];
              if (selectedOption && description !== "") {
                add(selectedOption.type, description);
                resetAndClose();
              } else {
                alert(
                  "Please make sure to select a type and include a description."
                );
              }
            }}
          />
          <Button
            label={
              <span className={styles.buttonText}>
                <FaBan />
                <p>Cancel</p>
              </span>
            }
            type={ButtonTypes.cancel}
            onClick={() => {
              resetAndClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddFlag;

interface AddFlagProps {
  show: boolean;
  close: () => void;
  add: (type: reports_flags_types, description: string) => void;
}

interface Flag {
  label: string;
  type: reports_flags_types;
  placeholder: string;
}
