import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

import { BoostState, BoostUpdateQuery } from "./types";

import { boosts } from "@mindprint-learning/api-lib";
import api from "services/api";

const initialState: BoostState = {
  creating: false,
  editing: false,
  deleting: false,
  updating: false,
  loading: false,
  boosts: [],
  current: {} as boosts,
};

export const createBoost = createAsyncThunk(
  "boost/create",
  async (boost: boosts) => {
    const response = await api.boosts.create(boost);

    return response.data;
  }
);

export const updateBoost = createAsyncThunk(
  "boost/update",
  async ({ id, boost }: BoostUpdateQuery) => {
    const response = await api.boosts.update(id, boost);

    return response.data;
  }
);

export const deleteBoost = createAsyncThunk(
  "boost/delete",
  async (id: number) => {
    const response = await api.boosts.delete(id);

    return response.data;
  }
);

export const fetchBoosts = createAsyncThunk("boost/fetchAll", async () => {
  const response = await api.boosts.fetch();

  return response.data;
});

export const fetchBoost = createAsyncThunk(
  "boost/fetch",
  async (id: string | number) => {
    const response = await api.boosts.get(id);

    return response.data;
  }
);

export const boostSlice = createSlice({
  name: "boost",
  initialState,
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBoost.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBoost.fulfilled, (state, action) => {
        state.loading = false;
        state.current = action.payload;
      })
      .addCase(fetchBoost.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createBoost.pending, (state) => {
        state.creating = true;
      })
      .addCase(createBoost.fulfilled, (state, action) => {
        state.creating = false;
        state.boosts = [...state.boosts, action.payload];
        toast.success(`Successfully created ${action.payload.title}`);
      })
      .addCase(createBoost.rejected, (state) => {
        state.creating = false;
      })
      .addCase(updateBoost.pending, (state) => {
        state.updating = true;
      })
      .addCase(updateBoost.fulfilled, (state, action) => {
        state.updating = false;
        state.boosts = state.boosts.map((boost) => {
          if (boost.id === action.payload.id) {
            return action.payload;
          }
          return boost;
        });
        toast.success(`Successfully updated ${action.payload.title}`);
      })
      .addCase(updateBoost.rejected, (state) => {
        state.updating = false;
      })
      .addCase(deleteBoost.pending, (state) => {
        state.deleting = true;
      })
      .addCase(deleteBoost.fulfilled, (state, action) => {
        state.deleting = false;
        state.boosts = state.boosts.filter(
          (boost) => boost.id !== action.payload.id
        );
        toast.success(`Successfully deleted ${action.payload.title}`);
      })
      .addCase(deleteBoost.rejected, (state) => {
        state.deleting = false;
      })
      .addCase(fetchBoosts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBoosts.fulfilled, (state, action) => {
        state.loading = false;
        state.boosts = action.payload;
      })
      .addCase(fetchBoosts.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const setCurrent = boostSlice.actions.setCurrent;
export const setEditing = boostSlice.actions.setEditing;

export default boostSlice.reducer;
