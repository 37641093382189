import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EnrollmentState, EnrollData } from "./types";

import api from "services/api";

const initialState: EnrollmentState = {
  newEnrolledList: [],
  sectionEnrollLoading: false,
  sectionEnrollError: false,
  enrollSuccessToast: false,
  enrollErrorToast: false,
};

export const sectionEnroll = createAsyncThunk(
  "enrollment/sectionEnroll",
  async (data: EnrollData) => {
    return (await api.enrollment.sectionEnroll(data)).data;
  }
);

export const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {
    addUserToEnrollList: (state, action) => {
      state.newEnrolledList?.push(action.payload);
    },
    removeUserFromEnrollList: (state, action) => {
      const removedUser = action.payload;
      state.newEnrolledList = state.newEnrolledList.filter(
        (user) => user.id !== removedUser.id
      );
    },
    setEnrollSuccessToast: (state, action) => {
      state.enrollSuccessToast = action.payload;
    },
    setEnrollErrorToast: (state, action) => {
      state.enrollErrorToast = action.payload;
    },
    clearEnrollList: (state) => {
      state.newEnrolledList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sectionEnroll.pending, (state) => {
        state.sectionEnrollLoading = true;
      })
      .addCase(sectionEnroll.fulfilled, (state, action) => {
        state.sectionEnrollLoading = false;
      })
      .addCase(sectionEnroll.rejected, (state) => {
        state.sectionEnrollLoading = false;
      });
  },
});

// Actions
export const {
  addUserToEnrollList,
  removeUserFromEnrollList,
  setEnrollSuccessToast,
  setEnrollErrorToast,
  clearEnrollList,
} = enrollmentSlice.actions;

export default enrollmentSlice.reducer;
