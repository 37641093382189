import { AxiosInstance } from "axios";
import { SATACTReportProps } from "@mindprint-learning/design";

const satActReportApi = (api: AxiosInstance) => {
  const url = window.location.pathname;
  const actUrl = "act-report";

  return {
    getSatActReport: (id: number | undefined) => {
      if (url.includes(actUrl)) {
        const path = id ? `/assessments/${id}/reports/test-prep` : null;
        return api.get<SATACTReportProps>(`${path}`, {
          params: {
            type: "act",
          },
          headers: {
            //@ts-ignore
            "x-new-assessment-flow": true,
          },
        });
      } else {
        const path = id ? `/assessments/${id}/reports/test-prep` : null;
        return api.get<SATACTReportProps>(`${path}`, {
          params: {
            type: "sat",
          },
          headers: {
            //@ts-ignore
            "x-new-assessment-flow": true,
          },
        });
      }
    },
  };
};

export default satActReportApi;
