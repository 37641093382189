import { useState, useEffect } from "react";
import { FaPlus, FaBan } from "react-icons/fa6";
import {
  Modal,
  Label,
  Select,
  Input,
  TextArea,
  Button,
  ButtonTypes,
} from "@mindprint-learning/design";

import { useAppSelector } from "store/hooks";

import {
  CATEGORYOPTIONS,
  ORDEROPTIONS,
  RULELIBRARY,
} from "./CustomRule.constants";
import { CustomRuleProps, FormData, UpdatedRuleType } from "./CustomRule.types";

// shared modals styles
import styles from "../modals.module.css";

// styles specific for this file
import classes from "./CustomRule.module.css";

const CustomRule = ({
  show,
  close,
  create,
  rule,
  update,
  onCancel,
}: CustomRuleProps) => {
  const assessmentId = useAppSelector(
    (state) =>
      state.rules.selectedV1Assessment?.data?.scores.formatted.assessment_id
  );

  const [typeIndex, setTypeIndex] = useState(-1);
  const [selectedRule, setSelectedRule] = useState("");
  const [label, setLabel] = useState("");
  const [content, setContent] = useState("");
  const [orderIndex, setOrderIndex] = useState(-1);

  useEffect(() => {
    if (rule.length > 0) {
      const ruleInfo = rule[0];
      setTypeIndex(
        CATEGORYOPTIONS.findIndex((option) => option.value === ruleInfo.type)
      );
      setLabel(ruleInfo.label);
      setContent(ruleInfo.content);
      setOrderIndex(ruleInfo.order - 1);
    }
  }, [rule]);

  function handleRuleChange(e: any) {
    const selectedRule = e.target.value;
    setSelectedRule(selectedRule);

    // populate Label and Description fields
    RULELIBRARY.forEach((group) => {
      Object.entries(group).forEach(([groupName, options]) => {
        options.forEach((option) => {
          if (option.label === selectedRule) {
            setLabel(option.label);
            setContent(option.description);
          }
        });
      });
    });
  }

  const isValidForm =
    typeIndex >= 0 &&
    typeIndex <= 2 &&
    label.length > 0 &&
    content.length > 0 &&
    orderIndex >= 0 &&
    orderIndex <= 9;

  function handleSubmit(e: any) {
    e.preventDefault();

    if (rule.length > 0) {
      // editing a rule
      const updatedRule: UpdatedRuleType = {
        content,
        label,
        id: rule[0].id,
        order: ORDEROPTIONS[orderIndex]?.value,
        type: CATEGORYOPTIONS[typeIndex]?.value,
      };

      update(updatedRule);
    } else {
      // create new custom rule
      const formData: FormData = {
        assessmentId,
        content,
        label,
        order: ORDEROPTIONS[orderIndex]?.value,
        type: CATEGORYOPTIONS[typeIndex]?.value,
        version: 1,
      };

      create(formData);
    }

    resetAndClose();
  }

  function handleCancel(e: any) {
    e.preventDefault();
    onCancel();
    resetAndClose();
  }

  function resetAndClose() {
    setTypeIndex(-1);
    setSelectedRule("");
    setLabel("");
    setContent("");
    setOrderIndex(-1);
    close();
  }

  return (
    <Modal
      show={show}
      handleClose={() => resetAndClose()}
      title="Add a Custom Rule"
    >
      <div className={styles.maxWidth700}>
        <form onSubmit={handleSubmit} className={styles.customRuleForm}>
          <section>
            <Label htmlFor="customRule_select_category">Category *</Label>
            <Select
              id="customRule_select_category"
              options={CATEGORYOPTIONS.map((value) => {
                return {
                  label: value.label,
                };
              })}
              onChange={setTypeIndex}
              selectedIndex={typeIndex}
            />
          </section>

          <section className={classes.selectWrapper}>
            <Label htmlFor="customRule_select_label">Rule Library</Label>

            <select
              className={classes.selectField}
              value={selectedRule}
              onChange={handleRuleChange}
            >
              <option value="">Select</option>
              {RULELIBRARY.map((group) =>
                Object.entries(group).map(([groupName, options]) => (
                  <optgroup key={groupName} label={groupName}>
                    {options.map((option) => (
                      <option key={option.label} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </optgroup>
                ))
              )}
            </select>
          </section>

          <section>
            <Input
              id="customRule_input_label"
              label="Custom Label *"
              value={label}
              onChange={setLabel}
            />
          </section>

          <section className={styles.textArea}>
            <Label htmlFor="customRule_textArea_description">
              Custom Description *
            </Label>
            <TextArea
              id="customRule_textArea_description"
              value={content}
              onChange={setContent}
            />
          </section>

          <section>
            <Label htmlFor="customRule_select_order">Order *</Label>
            <Select
              id="customRule_select_order"
              options={ORDEROPTIONS.map((value) => {
                return {
                  label: value.label,
                };
              })}
              onChange={setOrderIndex}
              selectedIndex={orderIndex}
            />
          </section>

          <section className={styles.buttonContainer}>
            {rule.length > 0 ? (
              <Button
                label="Update Rule"
                type={ButtonTypes.ok}
                disabled={!isValidForm}
              />
            ) : (
              <Button
                label={
                  <span className={styles.buttonText}>
                    <FaPlus />
                    <p>Add Rule</p>
                  </span>
                }
                type={ButtonTypes.ok}
                disabled={!isValidForm}
              />
            )}
            <Button
              label={
                <span className={styles.buttonText}>
                  <FaBan />
                  <p>Cancel</p>
                </span>
              }
              type={ButtonTypes.cancel}
              onClick={handleCancel}
            />
          </section>
          <p>* Required</p>
        </form>
      </div>
    </Modal>
  );
};

export default CustomRule;
