import loadable from "@loadable/component";
import { users_role } from "@mindprint-learning/api-lib";
import ReportPreview from "modules/Rules/RulesPage/ReportPreview";
import RulesDetail from "modules/Rules/RulesPage/RulesDetail";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/store";

const RulesPage = loadable(() => import("modules/Rules/RulesPage"));

const Rules = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);

  return (
    <>
      {user && user.role === users_role.god_admin && (
        <>
          <Routes>
            <Route path="" element={<RulesPage />} />
            <Route path=":subid" element={<RulesDetail />} />
            <Route
              path=":subid/preview/mindprint-profile"
              element={<ReportPreview />}
            />
          </Routes>
        </>
      )}
    </>
  );
};

export default Rules;
