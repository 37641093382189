import { Outlet, Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";

import { RootState } from "store/store";
import { useAppSelector } from "store/hooks";

const StrategiesPage = loadable(
  () => import("modules/Strategies/StrategiesPage")
);

const StrategiesRoutes = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);

  return (
    <Routes>
      <Route path="*" element={<Outlet />}>
        {user && user?.role === "god_admin" ? (
          <Route path="*" element={<StrategiesPage />} />
        ) : (
          <Route path="" element={<div>Coming Soon</div>} />
        )}
      </Route>
    </Routes>
  );
};

export default StrategiesRoutes;
