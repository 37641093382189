import { AxiosInstance } from "axios";
import { TeacherTrialAPI } from "modules/TeacherTrial/types";

const teacherTrialApi = (api: AxiosInstance) => {
  return {
    fetchTeacherTrialData: () => {
      const path = "/trials";
      return api.get<TeacherTrialAPI>(path);
    },
  };
};

export default teacherTrialApi;
