import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { UserDetailState } from "./types";

import api, { UpdateProfilePictureRequest } from "services/api";
import { AssessmentPost } from "@mindprint-learning/api-lib";
import toast from "react-hot-toast";

const initialState: UserDetailState = {
  user: undefined,
  assessments: undefined,
  destructive: false,
  loading: true,
};

export const fetch = createAsyncThunk(
  "userDetail/fetch",
  async (id: number) => {
    return (await api.users.getOne(id)).data;
  }
);

export const fetchAssessments = createAsyncThunk(
  "userDetail/fetchAssessments",
  async (id: number) => {
    return (await api.users.getAssessments(id)).data;
  }
);

export const createAssessment = createAsyncThunk(
  "userDetail/createAssessment",
  async ({ user_id, type }: AssessmentPost) => {
    const data = {
      user_id: user_id,
      type: type,
    };
    return (await api.assessments.create(data)).data;
  }
);

export const uploadProfileImage = createAsyncThunk(
  "userDetail/uploadProfileImage",
  async ({
    userId,
    base64Image,
    backgroundColor,
    defaultImage,
  }: UpdateProfilePictureRequest) => {
    return (
      await api.uploadProfileImage({
        userId,
        base64Image,
        backgroundColor,
        defaultImage,
      })
    ).data;
  }
);

export const usersDetailSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    clear: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data[0];
        state.destructive = action.payload.destructive;
      })
      .addCase(fetchAssessments.fulfilled, (state, action) => {
        state.assessments = action.payload;
      })
      .addCase(uploadProfileImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        state.loading = false;
        if (state.user) state.user.profile_image = action.payload;
      })
      .addCase(createAssessment.rejected, (_state) => {
        toast.error("Oops! There was an error creating the assessment.", {
          position: "top-center",
        });
      })
      // do this in user detail...
      .addCase(createAssessment.fulfilled, (state, action) => {
        toast.success("Assignment was created!");
        if (state.assessments) {
          state.assessments = [...state.assessments, action.payload];
        } else {
          state.assessments = [action.payload];
        }
      });
  },
});

export const clear = usersDetailSlice.actions.clear;
export default usersDetailSlice.reducer;
