import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { NWEAReport } from "@mindprint-learning/design";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { fetchNWEAReport } from "./store";

import Message from "components/Message/Message";
import PageLoader from "components/PageLoader";

const StudentNWEAReport = () => {
  const data = useAppSelector(
    (state: RootState) => state.nweaReport.data?.report
  );
  const loading = useAppSelector(
    (state: RootState) => state.nweaReport.loading
  );
  const hasError = useAppSelector(
    (state: RootState) => state.nweaReport.hasError
  );

  const messageData = {
    header: "Data Loading Error",
    p1: "We apologize. The data did not load properly.",
    p2: "Please try again.",
    url: "/manage",
  };

  const dispatch = useAppDispatch();
  const params = useParams();

  useEffect(() => {
    const subid = params.subid ? params.subid : undefined;

    dispatch(fetchNWEAReport(subid));
  }, [dispatch, params.subid]);

  return (
    <>
      {loading && <PageLoader />}

      {data && !hasError && !loading && <NWEAReport report={data} />}

      {hasError && <Message {...messageData} />}
    </>
  );
};

export default StudentNWEAReport;
