import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RulesState, AddFlagData } from "./types";
import {
  FormData,
  UpdatedRuleType,
} from "modules/Rules/RulesPage/ui/modals/CustomRule";

import api from "services/api";

const initialState: RulesState = {
  v1Assessments: undefined,
  selectedV1Assessment: undefined,
  v1Profile: undefined,
  v2Report: undefined,
  loading: false,
  dataLoading: false,
  reportStatusLoading: false,
  reportStatusError: false,
  customRuleError: false,
};

export const fetchV1Assessments = createAsyncThunk(
  "rules/fetchV1Assessments",
  async () => {
    return (await api.rules.get()).data;
  }
);

export const fetchV1Assessment = createAsyncThunk(
  "rules/fetchV1Assessment",
  async (subid: string) => {
    return (await api.rules.getOne(subid)).data;
  }
);

export const fetchV2Profile = createAsyncThunk(
  "rules/fetchV2Profile",
  async (subid: string) => {
    return (await api.rules.getV2Profile(subid)).data;
  }
);

export const process = createAsyncThunk(
  "rules/process",
  async (subid: string) => {
    return (await api.rules.process(subid)).data;
  }
);

export const addFlag = createAsyncThunk(
  "rules/addFlag",
  async ({ reportId, type, description }: AddFlagData) => {
    return await api.rules.addFlag(reportId, type, description);
  }
);

export const updateReportStatus = createAsyncThunk(
  "rules/updateReportStatus",
  async ({
    reportId,
    reportStatus,
  }: {
    reportId: number;
    reportStatus: string;
  }) => {
    return await api.rules.updateReportStatus(reportId, reportStatus);
  }
);

export const createCustomRule = createAsyncThunk(
  "rules/createCustomRule",
  async (formData: FormData) => {
    return (await api.rules.createCustomRule(formData)).data;
  }
);

export const updateCustomRule = createAsyncThunk(
  "rules/updateCustomRule",
  async (updatedRule: UpdatedRuleType) => {
    return (await api.rules.updateCustomRule(updatedRule)).data;
  }
);

export const deleteCustomRule = createAsyncThunk(
  "rules/deleteCustomRule",
  async (id: number) => {
    return (await api.rules.deleteCustomRule(id)).data;
  }
);

export const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    clear: () => {
      return initialState;
    },
    clearReport: (state) => {
      state.v2Report = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchV1Assessment.pending, (state) => {
        state.loading = true;
        state.selectedV1Assessment = undefined;
      })
      .addCase(fetchV1Assessment.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedV1Assessment = action.payload;
      })
      .addCase(fetchV1Assessments.pending, (state) => {
        state.loading = true;
        state.v1Assessments = undefined;
      })
      .addCase(fetchV1Assessments.fulfilled, (state, action) => {
        state.loading = false;
        state.v1Assessments = action.payload;
      })
      .addCase(process.pending, (state) => {
        state.dataLoading = true;
      })
      .addCase(process.fulfilled, (state, action) => {
        state.dataLoading = false;
        state.selectedV1Assessment = action.payload;
      })
      .addCase(fetchV2Profile.fulfilled, (state, action) => {
        state.dataLoading = false;
        state.v2Report = action.payload;
      })
      .addCase(addFlag.fulfilled, (state, action) => {
        state.dataLoading = false;

        if (state.selectedV1Assessment) {
          state.selectedV1Assessment.mp_profile_flags = action.payload.data;
        }
      })
      .addCase(updateReportStatus.pending, (state) => {
        state.reportStatusLoading = true;
        state.reportStatusError = false;
      })
      .addCase(updateReportStatus.fulfilled, (state) => {
        state.reportStatusLoading = false;
      })
      .addCase(updateReportStatus.rejected, (state) => {
        state.reportStatusLoading = false;
        state.reportStatusError = true;
      })
      .addCase(createCustomRule.pending, (state) => {
        state.dataLoading = true;
      })
      .addCase(createCustomRule.fulfilled, (state, action) => {
        state.dataLoading = false;
        // @ts-ignore
        state.selectedV1Assessment.data.custom_rules.push(action.payload.data);
      })
      .addCase(createCustomRule.rejected, (state) => {
        state.dataLoading = false;
        state.customRuleError = true;
      })
      .addCase(updateCustomRule.pending, (state) => {
        state.dataLoading = true;
      })
      .addCase(updateCustomRule.fulfilled, (state, action) => {
        state.dataLoading = false;
        // @ts-ignore
        state.selectedV1Assessment.data.custom_rules.push(action.payload.data);
      })
      .addCase(updateCustomRule.rejected, (state) => {
        state.dataLoading = false;
        state.customRuleError = true;
      })
      .addCase(deleteCustomRule.pending, (state) => {
        state.dataLoading = true;
      })
      .addCase(deleteCustomRule.fulfilled, (state, action) => {
        state.dataLoading = false;
      })
      .addCase(deleteCustomRule.rejected, (state) => {
        state.dataLoading = false;
        state.customRuleError = true;
      });
  },
});

export const clear = rulesSlice.actions.clear;
export const clearReport = rulesSlice.actions.clearReport;

export default rulesSlice.reducer;
