import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { User, users_role } from "@mindprint-learning/api-lib";
import { AuthState, Credentials, RouteRoots } from "./types";
import api, { UpdateProfilePictureRequest } from "services/api";

const initialState: AuthState = {
  user: null,
  userOrg: null,
  userSites: null,
  token: null,
  routeRoots: null,
  loading: false,
  checked: false,
};

export const signin = createAsyncThunk(
  "auth/signin",
  async (credentials: Credentials) => {
    const response = await api.auth.signin(credentials);
    return response.data;
  }
);

export interface EdlinkPost {
  code: string;
  type: string;
}
export const edlinkSignin = createAsyncThunk(
  "auth/edlink/signin",
  async ({ code, type }: EdlinkPost) => {
    const response = await api.auth.edlinkSignin({ code, type });
    return response.data;
  }
);

export const verify = createAsyncThunk("auth/verify", async () => {
  const response = await api.auth.verify();
  return response.data;
});

export const getUserOrg = createAsyncThunk(
  "users/user-organization",
  async (userId: number) => {
    const response = await api.users.getUserOrg(userId);
    return response.data;
  }
);

export const getUserSites = createAsyncThunk(
  "users/user-sites",
  async (userId: number) => {
    const response = await api.users.getUserSites(userId);
    return response.data;
  }
);

export const resetPasswordRequest = createAsyncThunk(
  "auth/reset-password-request",
  async (email: string) => {
    const response = await api.auth.resetPasswordRequest(email);
    return response.data;
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (data: any) => {
    const response = await api.auth.resetPassword(data);
    return response.data;
  }
);

export const update = createAsyncThunk("auth/update", async (data: User) => {
  return (await api.auth.updateUser(data)).data;
});

export const updateStudentPassword = createAsyncThunk(
  "password/student/reset",
  async (data: any) => {
    const response = await api.auth.updateStudentPassword(data);
    return response.data;
  }
);

export const validateTeacherPassword = createAsyncThunk(
  "password/validate",
  async (data: any) => {
    const response = await api.auth.validateTeacherPassword(data);
    return response.data;
  }
);

export const uploadProfileImage = createAsyncThunk(
  "auth/uploadProfileImage",
  async ({
    userId,
    base64Image,
    backgroundColor,
    defaultImage,
  }: UpdateProfilePictureRequest) => {
    return (
      await api.uploadProfileImage({
        userId,
        base64Image,
        backgroundColor,
        defaultImage,
      })
    ).data;
  }
);

interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
}
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ oldPassword, newPassword }: ChangePasswordRequest) => {
    return (await api.auth.changePassword(oldPassword, newPassword)).data;
  }
);

const getRouteRootsForRole = (
  role: users_role | undefined,
  userOrg: any,
  userSites: any = null,
  user: any = null
): RouteRoots => {
  let root: RouteRoots = {
    manage: "",
    assess: "",
    reports: "",
    strategies: "",
  };

  if (role === users_role.god_admin) {
    root.manage = "orgs";
  }

  if (role === users_role.org_admin || role === users_role.site_admin) {
    const organizationId = userOrg.data[0].id;
    root.manage = `orgs/${organizationId}`;
  }

  if (role === users_role.teacher && userSites !== null && user !== null) {
    // check how many Sites they have
    // if more than 1, start at Sites table
    // if only 1, start at Sections table

    const organizationId = userOrg.data[0].id;
    const multipleSites = userSites.data.length > 1;

    if (multipleSites) {
      root.manage = `orgs/${organizationId}`;
    } else {
      root.manage = `orgs/${organizationId}/sites/${userSites.data[0].id}/users/${user.id}/sections`;
    }
  }

  return root;
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signout: () => {
      localStorage.removeItem("token");
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.loading = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        const { token, user } = action.payload;
        state.user = user;
        state.token = token;
        if (user.role !== users_role.student) {
          localStorage.setItem("token", token);
        }
        state.loading = false;
      })
      .addCase(signin.rejected, (state) => {
        state.loading = false;
      })
      .addCase(edlinkSignin.pending, (state) => {
        state.loading = true;
      })
      .addCase(edlinkSignin.fulfilled, (state, action) => {
        const { token, user } = action.payload;
        state.user = user;
        localStorage.setItem("token", token);
        state.loading = false;
      })
      .addCase(edlinkSignin.rejected, (state) => {
        state.loading = false;
      })
      .addCase(verify.pending, (state) => {
        state.loading = true;
        state.checked = false;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.loading = false;
        state.checked = true;
      })
      .addCase(verify.rejected, (state) => {
        state.loading = false;
        state.checked = true;
      })
      .addCase(getUserOrg.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserOrg.fulfilled, (state, action) => {
        state.userOrg = action.payload;
        state.routeRoots = getRouteRootsForRole(
          state.user?.role,
          state.userOrg
        );
        state.loading = false;
      })
      .addCase(getUserOrg.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUserSites.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserSites.fulfilled, (state, action) => {
        state.userSites = action.payload;
        state.routeRoots = getRouteRootsForRole(
          state.user?.role,
          state.userOrg,
          state.userSites,
          state.user
        );
        state.loading = false;
      })
      .addCase(getUserSites.rejected, (state) => {
        state.loading = false;
      })
      .addCase(resetPasswordRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPasswordRequest.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPasswordRequest.rejected, (state) => {
        state.loading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.loading = false;
      })
      .addCase(update.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        if (state.user) state.user.profile_image = action.payload;
      })
      .addCase(uploadProfileImage.rejected, (state) => {
        alert("OH NO!");
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  },
});

export const signout = authSlice.actions.signout;

export default authSlice.reducer;
