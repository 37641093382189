import styles from "./SideNav.module.css";
import { StickyContainer } from "@mindprint-learning/design";

export const SideNav = ({ children }: SideNavProps) => {
  return (
    <StickyContainer backgroundColor="#fff" top={"5.2rem"}>
      <div className={styles.linkContainer}>{children}</div>
    </StickyContainer>
  );
};

interface SideNavProps {
  children: React.ReactNode;
}

export const SideNavLink = ({
  children,
  selected,
  onClick,
}: SideNavLinkProps) => {
  return (
    <div
      className={styles.link}
      style={{
        fontWeight: selected ? 800 : 600,
        opacity: selected ? 1.0 : 0.7,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

interface SideNavLinkProps {
  children: React.ReactNode;
  selected: boolean;
  onClick: () => void;
}
