import { AxiosInstance } from "axios";
import { Site, SitesResponse } from "@mindprint-learning/api-lib";
import { SiteFormData } from "modules/Sites/SitesPage/SiteForm/SiteForm.types";

const sites = (api: AxiosInstance) => {
  return {
    getAllSites: () => {
      return api.get<SitesResponse>(`/sites`);
    },
    getOrgSites: (orgId: number) => {
      const params = {
        include: "counts",
        pageSize: 4000,
      };
      const orgSite = api.get<any>(`organizations/${orgId}/sites`, { params });
      return orgSite;
    },
    createSite: (data: SiteFormData) => {
      return api.post<Site>(`sites`, {
        name: data.name,
        organization_id: data.organization_id,
        // required & can't be empty strings
        address: "---",
        city: "---",
        state: "---",
        zip: "---",
      });
    },
    updateSite: (data: SiteFormData) => {
      return api.put<Site>(`sites/${data.id}`, data);
    },
    deleteSite: (data: Site) => {
      return api.delete<Site>(`sites/${data.id}`);
    },
  };
};

export default sites;
