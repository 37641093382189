import { Skills } from "@mindprint-learning/api-lib";

import styles from "./ScoresChart.module.css";

const H = 480;
const BOTTOM = H / 11;

const ScoresChart = ({ data }: ScoresChartProps) => {
  return (
    <div className={styles.main} style={{ height: H + BOTTOM }}>
      <div className={styles.labelContainer} style={{ height: H + BOTTOM }}>
        <div className={styles.chartLabel}>2.5</div>
        <div className={styles.chartLabel}>2.0</div>
        <div className={styles.chartLabel}>1.5</div>
        <div className={styles.chartLabel}>1.0</div>
        <div className={styles.chartLabel}>0.5</div>
        <div className={styles.chartLabel}>0.0</div>
        <div className={styles.chartLabel}>-0.5</div>
        <div className={styles.chartLabel}>-1.0</div>
        <div className={styles.chartLabel}>-1.5</div>
        <div className={styles.chartLabel}>-2.0</div>
        <div className={styles.chartLabel}>-2.5</div>
      </div>
      <div className={styles.wrapper}>
        {data && (
          <>
            <Section
              label={data.visual_motor_speed.label}
              accuracy={data.visual_motor_speed.accuracy.zScore}
              speed={data.visual_motor_speed.speed.zScore}
              efficiency={null}
              valid={data.visual_motor_speed.valid || false}
            />
            <Section
              label={data.processing_speed.label}
              accuracy={null}
              speed={data.processing_speed.speed.zScore}
              efficiency={null}
              valid={data.processing_speed.valid || false}
            />
            <Section
              label={data.attention.label}
              accuracy={data.attention.accuracy.zScore}
              speed={data.attention.speed.zScore}
              efficiency={data.attention.efficiency.zScore}
              valid={data.attention.valid || false}
            />
            <Section
              label={data.working_memory.label}
              accuracy={data.working_memory.accuracy.zScore}
              speed={data.working_memory.speed.zScore}
              efficiency={data.working_memory.efficiency.zScore}
              valid={data.working_memory.valid || false}
            />

            <Section
              label={data.flexible_thinking.label}
              accuracy={data.flexible_thinking.accuracy.zScore}
              speed={data.flexible_thinking.speed.zScore}
              efficiency={data.flexible_thinking.efficiency.zScore}
              valid={data.flexible_thinking.valid || false}
            />
            <Section
              label={data.verbal_reasoning.label}
              accuracy={data.verbal_reasoning.accuracy.zScore}
              speed={data.verbal_reasoning.speed.zScore}
              efficiency={data.verbal_reasoning.efficiency.zScore}
              valid={data.verbal_reasoning.valid || false}
            />
            <Section
              label={data.abstract_reasoning.label}
              accuracy={data.abstract_reasoning.accuracy.zScore}
              speed={data.abstract_reasoning.speed.zScore}
              efficiency={data.abstract_reasoning.efficiency.zScore}
              valid={data.abstract_reasoning.valid || false}
            />
            <Section
              label={data.spatial_perception.label}
              accuracy={data.spatial_perception.accuracy.zScore}
              speed={data.spatial_perception.speed.zScore}
              efficiency={data.spatial_perception.efficiency.zScore}
              valid={data.spatial_perception.valid || false}
            />
            <Section
              label={data.verbal_memory.label}
              accuracy={data.verbal_memory.accuracy.zScore}
              speed={data.verbal_memory.speed.zScore}
              efficiency={data.verbal_memory.efficiency.zScore}
              valid={data.verbal_memory.valid || false}
            />
            <Section
              label={data.visual_memory.label}
              accuracy={data.visual_memory.accuracy.zScore}
              speed={data.visual_memory.speed.zScore}
              efficiency={data.visual_memory.efficiency.zScore}
              valid={data.visual_memory.valid || false}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ScoresChart;

const Section = ({
  label,
  accuracy,
  speed,
  efficiency,
  valid,
}: SectionProps) => {
  let a = accuracy ? (accuracy / 2.5) * (H / 2) : 0;
  if (Math.abs(a) > H / 2) {
    a = a < 0 ? -H / 2 : H / 2;
  }
  let s = speed ? (speed / 2.5) * (H / 2) : 0;
  if (Math.abs(s) > H / 2) {
    s = s < 0 ? -H / 2 : H / 2;
  }
  let e = efficiency ? (efficiency / 2.5) * (H / 2) : 0;
  if (Math.abs(e) > H / 2) {
    e = e < 0 ? -H / 2 : H / 2;
  }

  return (
    <div className={styles.sectionWrapper} style={{ height: H + BOTTOM }}>
      <div className={styles.section}>
        <div
          className={styles.bar}
          style={{
            height: Math.abs(a),
            backgroundColor: valid ? "#45a1fb" : "#ff0000",
            marginTop: a < 0 ? H / 2 : H / 2 - a,
          }}
        />

        <div
          className={styles.bar}
          style={{
            height: Math.abs(s),
            backgroundColor: valid ? "#389867" : "#ff0000",
            marginTop: s < 0 ? H / 2 : H / 2 - s,
          }}
        />

        <div
          className={styles.bar}
          style={{
            height: Math.abs(e),
            backgroundColor: valid ? "#f79f49" : "#ff0000",
            marginTop: e < 0 ? H / 2 : H / 2 - e,
          }}
        />
        <div className={styles.grid} style={{ height: H }}>
          <div
            className={`${styles.gridLine} ${styles.top}`}
            style={{ backgroundColor: `rgba(0, 0, 0, 0.2)` }}
          />
          <div
            className={styles.gridLine}
            style={{ backgroundColor: `rgba(0, 0, 0, 0.1)` }}
          />
          <div
            className={styles.gridLine}
            style={{ backgroundColor: `rgba(0, 0, 0, 0.05)` }}
          />
          <div
            className={styles.gridLine}
            style={{ backgroundColor: `rgba(0, 0, 0, 0.01)` }}
          />
          <div
            className={styles.gridLine}
            style={{ backgroundColor: `rgba(0, 0, 0, 0.01)` }}
          />
          <div
            className={styles.gridLine}
            style={{ backgroundColor: `rgba(0, 0, 0, 0.01)` }}
          />
          <div
            className={styles.gridLine}
            style={{ backgroundColor: `rgba(0, 0, 0, 0.025)` }}
          />
          <div
            className={styles.gridLine}
            style={{ backgroundColor: `rgba(0, 0, 0, 0.05)` }}
          />
          <div
            className={styles.gridLine}
            style={{ backgroundColor: `rgba(0, 0, 0, 0.1)` }}
          />
          <div
            className={styles.gridLine}
            style={{ backgroundColor: `rgba(0, 0, 0, 0.2)` }}
          />
        </div>
      </div>

      <div className={styles.label} style={{ height: BOTTOM }}>
        {label}
      </div>
    </div>
  );
};
// invalid: #ff0000, efficiency: f79f49

interface SectionProps {
  label: string;
  accuracy: number | null;
  speed: number | null;
  efficiency: number | null;
  valid: boolean;
}

interface ScoresChartProps {
  data: Skills;
}
