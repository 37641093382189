import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Section } from "@mindprint-learning/api-lib";

import { SectionFormData } from "../SectionsPage/SectionForm/SectionForm.types";
import { SectionsState, SectionEnrollData } from "./types";

import api from "services/api";

const initialState: SectionsState = {
  list: undefined,
  destructive: false,
  users: undefined,
  loading: false,
  hasError: false,
  showSectionForm: false,
  selectedSection: null,
  deletedSection: null,
};

export const fetchUsersSections = createAsyncThunk(
  "sections/usersSections",
  async (userId: number) => {
    return (await api.sections.getUsersSections(userId)).data;
  }
);

export const fetchSiteSections = createAsyncThunk(
  "sections/fetch",
  async (siteId: number | undefined) => {
    return (await api.sections.getSiteSections(siteId)).data;
  }
);

export const fetchUsers = createAsyncThunk(
  "sections/fetchUsers",
  async (siteId: number | undefined) => {
    return (await api.sections.getUsers(siteId)).data;
  }
);

export const createSection = createAsyncThunk(
  "sections/create",
  async (data: SectionFormData) => {
    return (await api.sections.createSection(data)).data;
  }
);

export const updateSection = createAsyncThunk(
  "sections/update",
  async (data: SectionFormData) => {
    return (await api.sections.updateSection(data)).data;
  }
);

export const deleteSection = createAsyncThunk(
  "sections/delete",
  async (data: Section) => {
    return (await api.sections.deleteSection(data)).data;
  }
);

export const enrollTeacherSection = createAsyncThunk(
  "sections/enroll",
  async (data: SectionEnrollData) => {
    return (await api.sections.enrollTeacherSection(data)).data;
  }
);

export const sectionSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {
    setShowSectionForm: (state, action) => {
      state.showSectionForm = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setDeletedSection: (state, action) => {
      state.deletedSection = action.payload;
    },
    clearSectionsState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersSections.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsersSections.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
      })
      .addCase(fetchUsersSections.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchSiteSections.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSiteSections.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data;
        state.destructive = action.payload.destructive;
      })
      .addCase(fetchSiteSections.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data;
      })
      .addCase(createSection.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSection.fulfilled, (state, action) => {
        state.loading = false;
        state.list?.push(action.payload);
        state.selectedSection = null;
      })
      .addCase(createSection.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(updateSection.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSection.fulfilled, (state, action) => {
        state.loading = false;
        const updatedSection = action.payload;
        const updatedSectionIndex = state.list?.findIndex(
          (section) => section.id === updatedSection.id
        );
        state.list![updatedSectionIndex!] = updatedSection;
        state.selectedSection = null;
      })
      .addCase(updateSection.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(deleteSection.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        state.loading = false;
        const deletedSection = action.payload;
        state.list = state.list?.filter(
          (section) => section.id !== deletedSection.id
        );
        state.deletedSection = null;
      })
      .addCase(deleteSection.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export const clearSectionsState = sectionSlice.actions.clearSectionsState;
export const setShowSectionForm = sectionSlice.actions.setShowSectionForm;
export const setSelectedSection = sectionSlice.actions.setSelectedSection;
export const setDeletedSection = sectionSlice.actions.setDeletedSection;

export default sectionSlice.reducer;
