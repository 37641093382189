import { Divider } from "@mindprint-learning/design";
import { ReactNode, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import styles from "./PageSection.module.css";

export const PageSection = ({
  title,
  children,
  sectionRef,
  onInView,
}: SectionProps) => {
  return (
    <InViewComponent
      onChange={(value: boolean) => {
        if (value === true && onInView) onInView();
      }}
    >
      <h2>{title}</h2>
      <div ref={sectionRef} className={styles.main}>
        <div className={styles.sectionWrapper}>{children}</div>
      </div>
    </InViewComponent>
  );
};

export const PageSubsection = ({
  title,
  children,
  description,
}: SectionProps) => {
  return (
    <div className={styles.mainSubsection}>
      <div className={styles.titleContainer}>
        <h3>{title}</h3>
        <Divider />
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.subsectionWrapper}>{children}</div>
    </div>
  );
};

interface SectionProps {
  title: string;
  children: ReactNode;
  onInView?: () => void;
  sectionRef?: any;
  description?: string;
}

const InViewComponent = ({ children, onChange }: InViewComponentProps) => {
  const { ref, inView } = useInView({
    threshold: [0.5],
    rootMargin: "-80px 0px 0px 0px",
  });

  useEffect(() => {
    onChange(inView);
  }, [onChange, inView]);

  return <div ref={ref}>{children}</div>;
};

interface InViewComponentProps {
  children: ReactNode;
  onChange: (inView: boolean) => void;
}
