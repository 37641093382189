import { AxiosInstance } from "axios";
import { User } from "@mindprint-learning/api-lib";
import { AuthResult, Credentials } from "modules/Auth/store/types";
import { EdlinkPost } from "modules/Auth/store";

const auth = (
  authServiceClient: AxiosInstance,
  apiServiceClient: AxiosInstance
) => {
  return {
    verify: () => authServiceClient.get<AuthResult>("/api/auth/verify"),
    signin: (credentials: Credentials) =>
      authServiceClient.post<AuthResult>("/api/auth/signin", credentials),
    edlinkSignin: (data: EdlinkPost) =>
      authServiceClient.post<AuthResult>("/api/auth/sso", {
        code: data.code,
        type: data.type,
      }),
    resetPasswordRequest: (email: string) =>
      authServiceClient.post("/password/reset-request", { email }),
    resetPassword: (data: any) =>
      authServiceClient.post("/password/reset", data, {
        headers: {},
      }),
    validateTeacherPassword: (data: any) =>
      authServiceClient.post("password/validate", data, {
        headers: {},
      }),
    updateStudentPassword: (data: any) =>
      authServiceClient.post("/password/student/reset", data, {
        headers: {},
      }),
    verifyResetToken: (data: any) =>
      authServiceClient.post(`/token/verify`, data),
    updateUser: (user: User) =>
      apiServiceClient.put("admin/users", { data: user }),
    changePassword: (oldPassword: string, newPassword: string) =>
      authServiceClient.put("/api/auth/change-password", {
        password: oldPassword,
        newPassword: newPassword,
      }),
  };
};

export default auth;
