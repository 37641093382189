import { useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { FaGear } from "react-icons/fa6";
import { User, users_role } from "@mindprint-learning/api-lib";
import {
  NavigationBar,
  Link,
  LinkTypes,
  Button,
  ButtonSizes,
  ButtonTypes,
  Tooltip,
  TooltipDirection,
} from "@mindprint-learning/design";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { signout } from "modules/Auth/store";

import { RouteLink } from "routes/Routes";

import styles from "./Header.module.css";
import UserProfileCircle from "components/UserProfileCircle";

const Header = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);

  const [showTooltip, setShowTooltip] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const goToLogin = () => {
    navigate("/login");
  };

  const goToSettings = () => {
    setShowTooltip(false);
    navigate("/settings");
  };

  const handleLogout = () => {
    setShowTooltip(false);
    dispatch(signout());

    navigate("/login");
  };

  return (
    <>
      <NavigationBar
        activePath={location.pathname}
        ctaContainer={
          <>
            {user ? (
              <Tooltip
                direction={TooltipDirection.bottom}
                show={showTooltip}
                close={() => setShowTooltip(false)}
                offsetHorizontal={120}
                content={
                  <ProfileMenu
                    logout={handleLogout}
                    settings={goToSettings}
                    user={user}
                  />
                }
              >
                <UserProfileCircle
                  user={user}
                  size={50}
                  onClick={() => setShowTooltip(true)}
                />
              </Tooltip>
            ) : (
              <Button
                label="Sign In"
                size={ButtonSizes.small}
                type={ButtonTypes.cta}
                onClick={goToLogin}
              />
            )}
          </>
        }
        links={
          user && user.role === users_role.god_admin
            ? [
                [
                  <Link
                    component={RouterLink}
                    to={`${RouteLink.Home}orgs`}
                    type={LinkTypes.nav}
                  >
                    Manage
                  </Link>,
                  <Link
                    component={RouterLink}
                    to={`${RouteLink.Rules}`}
                    type={LinkTypes.nav}
                  >
                    Pending Profiles
                  </Link>,
                  <Link
                    component={RouterLink}
                    to={`${RouteLink.TeacherTrials}`}
                    type={LinkTypes.nav}
                  >
                    Teacher Trials
                  </Link>,
                  <Link
                    component={RouterLink}
                    to={RouteLink.Strategies}
                    type={LinkTypes.nav}
                  >
                    Strategies
                  </Link>,
                ],
              ]
            : [
                [
                  <Link
                    component={RouterLink}
                    to={`${RouteLink.Home}`}
                    type={LinkTypes.nav}
                  >
                    Manage
                  </Link>,
                ],
              ]
        }
      />
    </>
  );
};

export default Header;

interface ProfileMenuProps {
  settings: () => void;
  logout: () => void;
  user: User;
}
const ProfileMenu = ({ settings, logout, user }: ProfileMenuProps) => {
  return (
    <div className={styles.profileMenu}>
      <UserProfileCircle user={user} size={200} />
      <div>
        {user.first_name} {user.last_name}
      </div>
      <div className={styles.buttonMenu}>
        {/* <Button
          label={
            <>
              <FaGear /> Account Settings
            </>
          }
          size={ButtonSizes.small}
          type={ButtonTypes.ok}
          onClick={settings}
        /> */}
        <Button
          label="Log Out"
          size={ButtonSizes.small}
          type={ButtonTypes.cta}
          primary={true}
          onClick={logout}
        />
      </div>
    </div>
  );
};
