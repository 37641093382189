import {
  Organization,
  OrganizationsReponse,
  UsersResponse,
} from "@mindprint-learning/api-lib";
import { AxiosInstance } from "axios";
import { OrganizationFormData } from "modules/Organizations/OrganizationsPage/OrganizationForm/OrganizationForm.types";

const organizationsApi = (api: AxiosInstance) => {
  return {
    getAllOrgs: () => {
      // pageSize is temp until FE can setup server-side rendering
      const params = {
        include: "counts",
        pageSize: 4000,
      };
      return api.get<OrganizationsReponse>(`organizations`, { params });
    },
    createOrg: (data: OrganizationFormData) => {
      return api.post<Organization>(`organizations`, {
        name: data.name,
        type: data.type,
        // do not send empty strings to api
        external_id: data.external_id?.length === 0 ? null : data.external_id,
        nwea_district_bid:
          data.nwea_district_bid?.length === 0 ? null : data.nwea_district_bid,
      });
    },
    updateOrg: (data: OrganizationFormData) => {
      return api.put<Organization>(`organizations/${data.id}`, {
        name: data.name,
        type: data.type,
        // do not send empty strings to api
        external_id: data.external_id?.length === 0 ? null : data.external_id,
        nwea_district_bid:
          data.nwea_district_bid?.length === 0 ? null : data.nwea_district_bid,
        old_org_id: null,
        archived_at: null,
      });
    },
    deleteOrg: (data: Organization) => {
      return api.delete<Organization>(`organizations/${data.id}`);
    },
    getUsers: () => {
      return api.get<UsersResponse>(`admin/users`);
    },
  };
};

export default organizationsApi;
