import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaCheck, FaArrowUpRightFromSquare, FaPlus } from "react-icons/fa6";

// MP Libraries
import {
  Container,
  Button,
  ButtonSizes,
  Divider,
  ButtonGroup,
  ButtonTypes,
  Select,
} from "@mindprint-learning/design";
import {
  assessments_status,
  reports_status,
} from "@mindprint-learning/api-lib";

// utils & services
import { date } from "utils/text";
import { V1Assessment } from "services/api/rules";

// Store
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import {
  fetchV1Assessment,
  process,
  addFlag,
  updateReportStatus,
  createCustomRule,
  updateCustomRule,
  deleteCustomRule,
} from "../store";

// ui components
import ScoreRangeTable from "./ui/ScoreRangeTable";
import ScoresChart from "./ui/ScoresChart";
import ClinicianSummary from "./ui/ClinicianSummary";
import Rules from "./ui/Rules";
import Flags from "./ui/Flags";
import AddFlag from "./ui/modals/AddFlag";
import Reprocess from "./ui/modals/Reprocess";
import InfoContainer from "./ui/InfoContainer";
import { FormData, UpdatedRuleType } from "./ui/modals/CustomRule";
import PageLoader from "components/PageLoader";

import styles from "../rules.module.css";

const RulesDetail = () => {
  // these should be moved to type and helper folders
  const types = [
    { label: "Flagged for review", value: 0 },
    { label: "Let's meet", value: 1 },
    { label: "Manually reviewed", value: 2 },
    { label: "Manually updated", value: 3 },
    { label: "Sent manually", value: 4 },
  ];

  const convertStringStatusToIndex = (s: any) => {
    if (s === reports_status.flagged_for_review) {
      return 0;
    }
    if (s === reports_status.lets_meet) {
      return 1;
    }
    if (s === reports_status.manually_reviewed) {
      return 2;
    }
    if (s === reports_status.manually_updated) {
      return 3;
    }
    if (s === reports_status.sent_manually) {
      return 4;
    }

    return 0;
  };

  const convertSelectIndexToString = (index: number) => {
    if (index === 0) {
      return reports_status.flagged_for_review;
    } else if (index === 1) {
      return reports_status.lets_meet;
    } else if (index === 2) {
      return reports_status.manually_reviewed;
    } else if (index === 3) {
      return reports_status.manually_updated;
    } else if (index === 4) {
      return reports_status.sent_manually;
    }

    return "";
  };

  const params = useParams();
  const dispatch = useAppDispatch();

  const { loading, reportStatusLoading, reportStatusError } = useAppSelector(
    (state: RootState) => state.rules
  );
  const dataLoading = useAppSelector(
    (state: RootState) => state.rules.dataLoading
  );
  const profile = useAppSelector(
    (state: RootState) => state.rules.selectedV1Assessment
  );

  const [showWarning, setShowWarning] = useState(false);
  const [showAddFlag, setShowAddFlag] = useState(false);
  const [showCNBSCores, setShowCNBScores] = useState(false);
  const [statusSaved, setStatusSaved] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [statusDropdownState, setStatusDropdownState] = useState(
    convertStringStatusToIndex(null)
  );

  useEffect(() => {
    if (profile && profile.mp_profile_status) {
      setStatusDropdownState(
        convertStringStatusToIndex(profile.mp_profile_status)
      );
    }
  }, [profile]);

  useEffect(() => {
    if (params.subid) {
      dispatch(fetchV1Assessment(params.subid));
    }
  }, [params.subid, dispatch]);

  const handleProcess = (profile: V1Assessment) => {
    if (profile.status === "processed") {
      setShowWarning(true);
    } else {
      if (params.subid) {
        dispatch(process(params.subid));
      }
    }
  };
  const handleStudentLearnerProfilePreview = () => {
    window.open(`/student-learner-profile/${params.subid}`);
  };

  const handleMindPrintProfilePreview = () => {
    // if (params.subid) dispatch(fetchV2Profile(params.subid));
    // navigate(`preview/mindprint-profile`);
    window.open(`${params.subid}/preview/mindprint-profile`);
  };

  const handleCreateCustomRule = async (formData: FormData) => {
    await dispatch(createCustomRule(formData));
    if (params.subid) {
      dispatch(fetchV1Assessment(params.subid));
    }
  };

  const handleUpdateCustomRule = async (updatedRule: UpdatedRuleType) => {
    await dispatch(updateCustomRule(updatedRule));
    if (params.subid) {
      dispatch(fetchV1Assessment(params.subid));
    }
  };

  const handleDeleteCustomRule = async (id: number) => {
    await dispatch(deleteCustomRule(id));
    if (params.subid) {
      dispatch(fetchV1Assessment(params.subid));
    }
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {profile && (
            <>
              {/* Modals */}
              <Reprocess
                show={showWarning}
                close={() => setShowWarning(false)}
                process={() => {
                  if (params.subid) {
                    dispatch(process(params.subid));
                  }
                }}
              />
              <AddFlag
                show={showAddFlag}
                close={() => setShowAddFlag(false)}
                add={(type, description) => {
                  if (profile.mp_profile_report_id) {
                    dispatch(
                      addFlag({
                        reportId: profile.mp_profile_report_id,
                        type: type,
                        description: description,
                      })
                    );
                  } else {
                    alert("Oops! Something went wrong.");
                  }
                }}
              />

              <InfoContainer profile={profile} />

              <Container>
                <div className={styles.main}>
                  <div className={styles.section}>
                    <div>
                      <h1>Assessment</h1>
                      <Divider />
                      <div className={styles.sectionDescription}>
                        {profile.status === assessments_status.processed
                          ? `This assessment has been processed. Below are the results
                          of the V2.0 Rules Engine matching and report generation. To re-process the
                          report, click the button below.`
                          : `This assessment was completed but has not been processed yet.
                        Click the button below to process the assessment using the
                        V2.0 Rules Engine.`}
                      </div>
                    </div>
                  </div>

                  <Button
                    label={
                      <span className={styles.buttonText}>
                        <FaCheck />
                        <p>
                          Process Profile{" "}
                          {profile.status === assessments_status.processed
                            ? "Again"
                            : ""}
                        </p>
                      </span>
                    }
                    onClick={() => handleProcess(profile)}
                    size={ButtonSizes.tiny}
                    type={ButtonTypes.ok}
                  />

                  <div className={styles.section}>
                    <div>
                      <h1>Reports</h1>
                      <Divider />
                      <div className={styles.sectionDescription}>
                        {profile.status === assessments_status.processed ? (
                          <>
                            This report was last updated{" "}
                            <em>{date(profile.mp_profile_updated_at)}</em>.
                          </>
                        ) : (
                          <>
                            Process the assessment to preview the MindPrint
                            Profile
                          </>
                        )}
                      </div>
                    </div>
                    {profile.status === assessments_status.processed && (
                      <div className={styles.buttonContainer}>
                        <Button
                          label={
                            <span className={styles.buttonText}>
                              <FaArrowUpRightFromSquare />
                              <p>Preview MindPrint Profile</p>
                            </span>
                          }
                          onClick={() => handleMindPrintProfilePreview()}
                          size={ButtonSizes.tiny}
                          primary={true}
                        />
                        <Button
                          label={
                            <span className={styles.buttonText}>
                              <FaArrowUpRightFromSquare />
                              <p>Preview Student Learner Profile</p>
                            </span>
                          }
                          onClick={() => handleStudentLearnerProfilePreview()}
                          size={ButtonSizes.tiny}
                          primary={true}
                        />
                      </div>
                    )}
                  </div>

                  <div className={styles.section}>
                    <div>
                      <h2>Status</h2>
                      <Divider />
                      <div className={styles.sectionDescription}>
                        {profile.mp_profile_status ? (
                          <>
                            The status of the MindPrint Profile Report is based
                            on automation rules that determine whether a report
                            can be sent automatically or is flagged for review.
                          </>
                        ) : (
                          <>
                            Process the assessment to see the status of this
                            student's MindPrint Profile Report.
                          </>
                        )}
                      </div>
                      <div className={styles.statusBox}>
                        {profile.mp_profile_status ===
                          reports_status.sent_automatically ||
                        profile.mp_profile_status ===
                          reports_status.sent_as_trial ? (
                          <b>This assessment was sent automatically.</b>
                        ) : (
                          <>
                            <Select
                              errorMessage={
                                reportStatusError
                                  ? "Unable to update status"
                                  : ""
                              }
                              id="addFlag_select_flagType"
                              options={types}
                              onChange={(value) => {
                                setStatusSaved(false);
                                setStatusDropdownState(value);
                              }}
                              selectedIndex={statusDropdownState}
                            />

                            <Button
                              label={
                                statusSaved ? "Status Saved" : "Update Status"
                              }
                              loading={reportStatusLoading}
                              disabled={statusSaved}
                              onClick={() => {
                                dispatch(
                                  updateReportStatus({
                                    reportId: profile.mp_profile_report_id,
                                    reportStatus:
                                      convertSelectIndexToString(
                                        statusDropdownState
                                      ),
                                  })
                                ).then((d: any) => {
                                  if (d.payload?.data?.status) {
                                    setStatusSaved(true);
                                    setStatusDropdownState(
                                      convertStringStatusToIndex(
                                        d.payload.data.status
                                      )
                                    );
                                  }
                                });
                              }}
                              type={ButtonTypes.cta}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.section}>
                    <div>
                      <h2>Flags</h2>
                      <Divider />

                      <div className={styles.sectionDescription}>
                        {profile.mp_profile_flags &&
                        profile.mp_profile_flags.length > 0 ? (
                          <>
                            Add flags to this report to document discrepancies
                            between the V1.0 and V2.0 report processing.
                          </>
                        ) : (
                          <>
                            This report has passed all of the automation checks.
                            However, flags can also be entered manually before a
                            full switchover to the V1.0 Rules Engine.
                          </>
                        )}
                      </div>
                    </div>
                    {profile.mp_profile_flags && (
                      <>
                        <Button
                          label={
                            <span className={styles.buttonText}>
                              <FaPlus />
                              <p>Add a Report Flag</p>
                            </span>
                          }
                          onClick={() => setShowAddFlag(true)}
                          size={ButtonSizes.tiny}
                          type={ButtonTypes.ok}
                        />
                        <Flags value={profile.mp_profile_flags} />
                      </>
                    )}

                    <Divider />
                  </div>

                  <div className={styles.section}>
                    <div>
                      <h2>Comments</h2>
                      <Divider />
                      {profile.comments !== "" && profile.comments ? (
                        <div
                          style={{
                            backgroundColor: "#fdcb6f",
                            padding: "1em 2em",
                            borderRadius: "4px",
                            color: "#fff",
                          }}
                        >
                          <div>Comments:</div>
                          <div>{profile.comments}</div>
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#ecf0f1",
                            padding: "1em 2em",
                            borderRadius: "4px",
                          }}
                        >
                          <div>No Comments</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.section}>
                    <div>
                      <h2>Raw Scores</h2>
                      <Divider />

                      <div className={styles.sectionDescription}>
                        {profile.data ? (
                          <>
                            Below are the assessment scores for the 10 cognitive
                            skills.
                          </>
                        ) : (
                          <>
                            Process the assessment to see the score results for
                            this assessment
                          </>
                        )}
                      </div>
                      <div className={styles.sectionDescription}>
                        {profile.data?.scores.cnb && showCNBSCores ? (
                          <>
                            <Button
                              label="Hide Raw Scores"
                              onClick={() => setShowCNBScores(false)}
                              size={ButtonSizes.tiny}
                              type={ButtonTypes.ok}
                            />
                            <pre
                              style={{
                                fontFamily: "monospace",
                                fontSize: "0.875em",
                              }}
                            >
                              {JSON.stringify(
                                profile.data?.scores.cnb,
                                null,
                                2
                              )}
                            </pre>
                          </>
                        ) : (
                          <Button
                            label="Show Raw Scores"
                            onClick={() => setShowCNBScores(true)}
                            size={ButtonSizes.tiny}
                            type={ButtonTypes.ok}
                          />
                        )}
                      </div>
                    </div>
                    {profile.data && profile.data.scores.skills && (
                      <ScoresChart data={profile.data.scores.skills} />
                    )}
                  </div>

                  {!dataLoading ? (
                    <>
                      {profile.data && (
                        <>
                          {profile.data.scores && (
                            <>
                              <Divider />
                              <div
                                style={{
                                  display: "flex",
                                  backgroundColor: "#ecf0f1",
                                  padding: "0.5em 1em",
                                  fontWeight: "bold",
                                  borderRadius: "0.25em",
                                  justifyContent: "center",
                                }}
                              >
                                Reversals:{" "}
                                {profile.data.scores.formatted?.reversals}
                              </div>
                              <Divider />
                              <ScoreRangeTable
                                data={profile.data.scores.skills}
                              />
                              <Divider />
                            </>
                          )}
                          {profile.data.rules && (
                            <>
                              <div className={styles.buttonContainer}>
                                <ButtonGroup
                                  buttons={["Clinician Summary", "Rules"]}
                                  selectedIndex={tabIndex}
                                  updateIndex={(value: number) =>
                                    setTabIndex(value)
                                  }
                                />
                              </div>
                              <Divider />
                              <div>
                                {tabIndex === 0 && (
                                  <ClinicianSummary
                                    data={profile.data.formatted_rules}
                                    // @ts-ignore
                                    customRules={profile.data.custom_rules}
                                    createCustomRule={handleCreateCustomRule}
                                    updateCustomRule={handleUpdateCustomRule}
                                    deleteCustomRule={handleDeleteCustomRule}
                                  />
                                )}
                                {tabIndex === 1 && (
                                  <Rules data={profile.data.rules} />
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <PageLoader />
                  )}
                </div>
              </Container>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RulesDetail;

/* {data && (
          <div className={styles.detailContainer}>
            <ScoreRangeTable data={data.scoreRanges} />

            <Divider />
            <div className={styles.buttonContainer}>
              <Button
                label="Clinician Summary"
                onClick={() => setTabIndex(0)}
                primary={tabIndex === 0}
              />
              <Button
                label="Rules"
                onClick={() => setTabIndex(1)}
                primary={tabIndex === 1}
              />
            </div>

            <div>
              {tabIndex === 0 && <div>CLINICIAN SUMMARY</div>}
              {tabIndex === 1 && <div>RULES</div>}
              <>
                <h2>Complex Reasoning</h2>

                {data.chosen_rules.complex_reasoning
                  .filter(
                    (rule) =>
                      !rule.v1Rule ||
                      (rule.v1Rule && rule.v1Rule.products.indexOf("TP_") === -1)
                  )
                  .map((rule, index) => {
                    return (
                      <RuleBox
                        key={`rule-box-rule-${index}-${rule.datasetid}`}
                        rule={rule}
                      />
                    );
                  })}
              </>
              <div>
                <h2>Memory</h2>
                <div>
                  {data.chosen_rules.memory
                    .filter(
                      (rule) =>
                        !rule.v1Rule ||
                        (rule.v1Rule &&
                          rule.v1Rule.products.indexOf("TP_") === -1)
                    )
                    .map((rule, index) => {
                      return (
                        <RuleBox
                          key={`rule-box-rule-${index}-${rule.datasetid}`}
                          rule={rule}
                        />
                      );
                    })}
                </div>
              </div>
              <div>
                <h2>Executive Function</h2>
                <div>
                  {data.chosen_rules.executive_function
                    .filter(
                      (rule) =>
                        !rule.v1Rule ||
                        (rule.v1Rule &&
                          rule.v1Rule.products.indexOf("TP_") === -1)
                    )
                    .map((rule, index) => {
                      return (
                        <RuleBox
                          key={`rule-box-rule-${index}-${rule.datasetid}`}
                          rule={rule}
                        />
                      );
                    })}
                </div>
              </div>
              <div>
                <h2>Processing Speed</h2>
                <div>
                  {data.chosen_rules.processing_speed
                    .filter(
                      (rule) =>
                        !rule.v1Rule ||
                        (rule.v1Rule &&
                          rule.v1Rule.products.indexOf("TP_") === -1)
                    )
                    .map((rule, index) => {
                      return (
                        <RuleBox
                          key={`rule-box-rule-${index}-${rule.datasetid}`}
                          rule={rule}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )} */
