import {
  rules,
  rules_templates,
  MindPrintProfileReport,
  reports_flags,
  assessments_status,
  reports_flags_types,
  OrderedRulesTemplates,
} from "@mindprint-learning/api-lib";
import { AxiosInstance } from "axios";
import { Scores } from "@mindprint-learning/api-lib";

import {
  FormData,
  UpdatedRuleType,
} from "modules/Rules/RulesPage/ui/modals/CustomRule";

const v1Assessments = (api: AxiosInstance) => {
  return {
    get: () => {
      return api.get<Array<V1Assessment>>(`/assessments/v1/`);
    },

    getOne: (subid: string) => {
      return api.get<V1Assessment>(`/assessments/v1/${subid}`);
    },

    process: (subid: string) => {
      return api.get<V1Assessment>(`/assessments/process-v2/${subid}`);
    },

    addFlag: (
      reportId: number,
      type: reports_flags_types,
      description: string
    ) => {
      return api.post<Array<reports_flags>>(`/reports/${reportId}/flags`, {
        type: type,
        description: description,
      });
    },

    updateReportStatus: (reportId: number, report_status: string) => {
      return api.put<Array<reports_flags>>(`/reports/${reportId}`, {
        report_status,
      });
    },

    getV2Profile: (subid: string) => {
      return api.get<MindPrintProfileReport>(
        `/reports/mindprint-profile/2/${subid}`
      );
    },

    createCustomRule: (formData: FormData) => {
      return api.post(`/custom-rules`, formData);
    },

    updateCustomRule: (updatedRule: UpdatedRuleType) => {
      return api.put(`/custom-rules/${updatedRule.id}`, updatedRule);
    },

    deleteCustomRule: (id: number) => {
      return api.delete(`/custom-rules/${id}`);
    },
    // getOne: (id: number) => {
    //   return api.get<UsersResponse>(`admin/users/${id}`);
    // },
    // getAssessments: (id: number) => {
    //   return api.get<Array<any>>(`admin/users/${id}/assessments`);
    // },
    // create: (data: User) => {
    //   return api.post<User>(`admin/users`, data);
    // },
    // update: (data: User) => {
    //   return api.post<User>(`admin/users/${data.id}`, data);
    // },
    // delete: (data: User) => {
    //   return api.delete<User>(`admin/users/${data.id}`);
    // },
  };
};

export default v1Assessments;

// export interface Rule {
//   datasetid: string;
//   category: string;
//   tier: string | null;
//   parent_tier: string | null;
//   strengths: string | null;
//   needs: string | null;
//   recommendations: string | null;
//   expression: string;
//   v1Match: boolean;
//   v1Rule: {
//     products: string;
//   };
// }

export interface V1Assessment {
  customer_id: number;
  family_id: number;
  subid: string;
  gender: string;
  birthdate: Date;
  age: number;
  first_name: string;
  last_name: string;
  created_at: Date;
  completed_at: Date;
  status: assessments_status;
  comments: string;
  mp_profile_report_id: number;
  mp_profile_status: string;
  mp_profile_flags: Array<reports_flags>;
  mp_profile_updated_at: Date;
  data?: Assessment;
}

export interface Assessment {
  rules: Array<Rule>;
  formatted_rules: {
    strengths: Array<OrderedRulesTemplates>;
    needs: Array<OrderedRulesTemplates>;
    recommendations: Array<OrderedRulesTemplates>;
  };
  scores: Scores;
}

export interface Rule extends rules {
  rules_templates: Array<rules_templates>;
}

// export interface ScoresData {
//   formatted: scores;
//   cnb: scores_cnb;
//   skills: Skills;
// }
// export interface RuleData {
//   abstract_reasoning_score_range: ScoreRanges;
//   attention_score_range: ScoreRanges;
//   attention_speed_score_range: ScoreRanges;
//   flexible_thinking_score_range: ScoreRanges;
//   processing_speed_score_range: ScoreRanges;
//   spatial_perception_score_range: ScoreRanges;
//   verbal_memory_score_range: ScoreRanges;
//   verbal_reasoning_score_range: ScoreRanges;
//   verbal_reasoning_speed_score_range: ScoreRanges;
//   visual_memory_score_range: ScoreRanges;
//   visual_motor_speed_score_range: ScoreRanges;
//   working_memory_score_range: ScoreRanges;
// }

// export enum ScoreRanges {
//   low_weakness = "low_weakness",
//   medium_weakness = "medium_weakness",
//   low_expected = "low_expected",
//   medium_expected = "medium_expected",
//   high_expected = "high_expected",
//   medium_strength = "medium_strength",
//   high_strength = "high_strength",
//   invalid = "invalid",
// }
