import { AxiosInstance } from "axios";
import { AcademicTableAPI } from "modules/AcademicTable/types";

const academicTableApi = (api: AxiosInstance) => {
  return {
    fetchAcademicTableData: (siteId: number) => {
      const path = siteId ? `/sites/${siteId}/academic-outcomes` : "";
      return api.get<AcademicTableAPI>(path);
    },
  };
};

export default academicTableApi;
