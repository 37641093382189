import { Outlet, Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";

import { useAppSelector } from "store/hooks";

const Breadcrumbs = loadable(() => import("components/Breadcrumbs"));

const Organizations = loadable(
  () => import("modules/Organizations/OrganizationsPage")
);
const Sites = loadable(() => import("modules/Sites/SitesPage"));
const SiteUsers = loadable(
  () => import("modules/Users/components/SiteUsers/SiteUsersPage")
);
const Sections = loadable(() => import("modules/Sections/SectionsPage"));
const SectionUsers = loadable(
  () => import("modules/Users/components/SectionUsers/SectionUsersPage")
);
const EnrollmentPage = loadable(
  () => import("modules/Enrollment/components/EnrollmentPage")
);

const AcademicTablePage = loadable(
  () => import("modules/AcademicTable/components/AcademicTablePage")
);
const CognitiveTablePage = loadable(
  () => import("modules/CognitiveTable/components/CognitiveTablePage")
);

const Manage = () => {
  const user = useAppSelector((state) => state.auth.user);
  const userSites = useAppSelector((state) => state.auth.userSites);

  let multipleSites;
  if (userSites?.data) {
    multipleSites = userSites?.data.length > 1;
  }

  return (
    <>
      {user && (
        <>
          <Routes>
            {user?.role === "god_admin" && (
              <Route
                path="*"
                element={
                  <>
                    <Breadcrumbs />
                    <Outlet />
                  </>
                }
              >
                {/* path has to match the navigate(`sites/${row.id}/users`)!!! */}
                <Route path="*" element={<Organizations />}>
                  <Route path=":organizationId" element={<Sites />}>
                    <Route path="sites/:siteId" element={<SiteUsers />}>
                      <Route
                        path="users/:siteUserId/sections"
                        element={<Sections />}
                      >
                        <Route path=":sectionId/" element={<SectionUsers />}>
                          {/* User Detail Page Route will go here */}

                          <Route path="enroll" element={<EnrollmentPage />} />
                        </Route>

                        {/* Section level Dashboards */}
                        <Route
                          path="academic-dashboard"
                          element={<AcademicTablePage />}
                        />
                        <Route
                          path="cognitive-dashboard"
                          element={<CognitiveTablePage />}
                        />
                      </Route>

                      {/* Site level Dashboards */}
                      <Route
                        path="academic-dashboard"
                        element={<AcademicTablePage />}
                      />
                      <Route
                        path="cognitive-dashboard"
                        element={<CognitiveTablePage />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            )}

            {(user?.role === "org_admin" || user?.role === "site_admin") && (
              <Route
                path="*"
                element={
                  <>
                    <Breadcrumbs />
                    <Outlet />
                  </>
                }
              >
                <Route path="*" element={<Sites />}>
                  <Route path="sites/:siteId" element={<SiteUsers />}>
                    <Route
                      path="users/:siteUserId/sections"
                      element={<Sections />}
                    >
                      <Route path=":sectionId/" element={<SectionUsers />}>
                        {/* User Detail Page Route will go here */}

                        <Route path="enroll" element={<EnrollmentPage />} />
                      </Route>

                      {/* Section level Dashboards */}
                      <Route
                        path="academic-dashboard"
                        element={<AcademicTablePage />}
                      />
                      <Route
                        path="cognitive-dashboard"
                        element={<CognitiveTablePage />}
                      />
                    </Route>

                    {/* Site level Dashboards */}
                    <Route
                      path="academic-dashboard"
                      element={<AcademicTablePage />}
                    />
                    <Route
                      path="cognitive-dashboard"
                      element={<CognitiveTablePage />}
                    />
                  </Route>
                </Route>
              </Route>
            )}

            {/* Teacher with multiple Sites */}
            {user?.role === "teacher" && multipleSites && (
              <Route
                path="*"
                element={
                  <>
                    <Breadcrumbs />
                    <Outlet />
                  </>
                }
              >
                {/* <Route path="*" element={<Sites />}> */}
                <Route path="*" element={<Sites />}>
                  <Route
                    path="sites/:siteId/users/:siteUserId/sections"
                    element={<Sections />}
                  >
                    <Route path=":sectionId/" element={<SectionUsers />}>
                      {/* User Detail Page Route will go here */}
                    </Route>

                    {/* Section level Dashboards */}
                    <Route
                      path="academic-dashboard"
                      element={<AcademicTablePage />}
                    />
                    <Route
                      path="cognitive-dashboard"
                      element={<CognitiveTablePage />}
                    />
                  </Route>
                </Route>
              </Route>
            )}

            {/* Teacher with one Site */}
            {user?.role === "teacher" && !multipleSites && (
              <Route
                path="*"
                element={
                  <>
                    <Breadcrumbs />
                    <Outlet />
                  </>
                }
              >
                {/* <Route path="*" element={<Sites />}> */}
                <Route path="*" element={<Sections />}>
                  <Route path=":sectionId/" element={<SectionUsers />}>
                    {/* User Detail Page Route will go here */}
                  </Route>

                  {/* Section level Dashboards */}
                  <Route
                    path="academic-dashboard"
                    element={<AcademicTablePage />}
                  />
                  <Route
                    path="cognitive-dashboard"
                    element={<CognitiveTablePage />}
                  />
                </Route>
              </Route>
            )}
          </Routes>
        </>
      )}
    </>
  );
};

export default Manage;
