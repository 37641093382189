import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { MPProfileState } from "./types";
import api from "services/api";
import { RootState } from "store/store";

const initialState: MPProfileState = {
  data: null,
  loading: false,
  hasError: false,
};

export const fetchMndPrintProfile = createAsyncThunk(
  "fetchMndPrintProfile",
  async (subid: string | undefined) => {
    return (await api.mindPrintProfile.getMindPrintProfile(subid)).data;
  }
);

const MindPrintProfileSlice = createSlice({
  name: "mindPrintProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMndPrintProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMndPrintProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchMndPrintProfile.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      });
  },
});

export default MindPrintProfileSlice.reducer;

// Selectors
export const getUpdatedProfileData = createSelector(
  (state: RootState) => state.mindPrintProfile.data,
  (data) => {
    const productUpdate = data?.data?.replaceAll(
      "/product",
      "https://my.mindprintlearning.com/products"
    );

    const searchUpdate = productUpdate?.replaceAll(
      "/search?",
      "https://my.mindprintlearning.com/search?"
    );

    const regexp = /<p>More.*Plan<\/a><\/p>/;
    const dataUpdate = searchUpdate?.replace(regexp, "");

    return dataUpdate;
  }
);
